import axios from "axios";

const axiosInstance = axios.create({
    baseURL:'https://admin.byebye404.com/api/'
    //baseURL:"http://localhost/api/"
})

export const axiosUserLog = ()=>{
    return(
        axios.create({
            //baseURL:"http://localhost/api/",
            baseURL:'https://admin.byebye404.com/api/',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
    )
}

export default axiosInstance