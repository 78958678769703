import React from "react";
import { useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const Header = () => {
  const [navbar, setNavbar] = useState(false);
  return (
    <>
      <div className="header landing-container">
        <div className="header-logo">
          <Link to="/">
            <img src="/assets/bb404_logo.png" alt="Bye Bye 404 Logo" />
            <p>Bye Bye 404</p>
          </Link>
        </div>
        <div className="menu-button">
          <i class="bi bi-list" onClick={() => setNavbar(true)}></i>
        </div>
        <div
          className={`header-navbar ${navbar ? "header-navbar--active" : ""}`}
        >
          <ul className="navbar-container">
            <li className="navbar-item navbar-item-close">
              <i class="bi bi-x-lg" onClick={() => setNavbar(false)}></i>
            </li>
            <li className="navbar-item">
              <Link to="/" onClick={() => setNavbar(false)}>
                Home
              </Link>
            </li>
            <li className="navbar-item">
              <HashLink to="/#characteristics" onClick={() => setNavbar(false)}>
                Characteristics
              </HashLink>
            </li>
            <li className="navbar-item">
              <HashLink to="/#details" onClick={() => setNavbar(false)}>
                Details
              </HashLink>
            </li>
            <li className="navbar-item">
              <HashLink to="/#regulations" onClick={() => setNavbar(false)}>
                Regulations
              </HashLink>
            </li>
            <li className="navbar-item">
              <HashLink to="/#contact-us" onClick={() => setNavbar(false)}>
                Contact us
              </HashLink>
            </li>
            <li className="navbar-item">
              <Link to="/login" onClick={() => setNavbar(false)}>
                Login
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
