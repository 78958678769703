import React, { useEffect, useState } from "react";
import { useRef } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { axiosUserLog } from "../../helpers/axiosInstance";

const Contact = () => {
  const[name, setName] = useState("")
  const[email, setEmail] = useState("")
  const[body, setBody] = useState("")

  const notifySuccess = () =>
    toast.success("Message sent successfully!", {
      position: "bottom-center",
    });

  const notifyError = () =>
    toast.error(
      "An error occurred while sending the message, please try again",
      {
        position: "bottom-center",
      }
    );

  const handleSendMessage = async(e) => {
    e.preventDefault();
    try{
      const {data} = await axiosUserLog().get(`admin/sites/link/emailsender/?name=${name}&email=${email}&body=${body}`)
      notifySuccess()
    }catch(err){
      notifyError()
    }
  };
  return (
    <>
      <div className="contact-us main-section landing-container" id="contact-us">
        <h2 className="section-title">Contact us</h2>
        <div className="contact-container">
          <form
            className="contact-form-container"
            onSubmit={(e) => handleSendMessage(e)}
          >
            <input type="text" onChange={(e)=>setName(e.target.value)} name="user_name" placeholder="Complete name" required/>
            <input type="email" onChange={(e)=>setEmail(e.target.value)} name="user_email" placeholder="Email" required/>
            <textarea
              name="message"
              cols="30"
              rows="10"
              placeholder="Message"
              onChange={(e)=>setBody(e.target.value)}
              required
            ></textarea>
            <input type="submit" value="Send" />
          </form>
          <Toaster />
          <img src="/assets/new_message.png" alt="Contact image" />
        </div>
      </div>
    </>
  );
};

export default Contact;
