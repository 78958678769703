import React, { Fragment } from "react"

import Icon from '../img/favicon-96x96.png';
import { Link } from "react-router-dom";
import { ClipboardData, Globe2, Link45deg, ExclamationTriangle, Bag, Collection, Tag, Gear, PersonCircle, ZoomIn, Pencil } from "react-bootstrap-icons";

const Sidebar = (props) => {
    return (

        <Fragment>
            <div className="sidebar-background">
                <div id="sidebar-container" className="{sidebarClass} border-right shadow-side-sm shadow-side-hover">

                    <div className="logo">
                        <img src={Icon} className="logo-sidebar mx-auto d-block border border-dark" alt="Logo Enciso systems"></img>
                    </div>

                    <div className="menu list-group-flush ">
                        <hr className="m-0"></hr>

                        <Link to="/main" className="text-white list-group-item list-group-item-action  p-1 text-center">
                            <ClipboardData className="d-block mx-auto my-1" size={40} />Dashboard</Link>


                        <Link to="/main/websites" className="text-white  list-group-item list-group-item-action    p-1 text-center">
                            <Globe2 className="d-block mx-auto my-1" size={40} />Websites</Link>
                        
                        <Link to="/main/accesibility" className="text-white  list-group-item list-group-item-action    p-1 text-center">
                            <PersonCircle className="d-block mx-auto my-1" size={40} />Accesibility</Link>

                        <Link to="/main/seo" className="text-white  list-group-item list-group-item-action    p-1 text-center">
                            <ZoomIn className="d-block mx-auto my-1" size={40} />SEO</Link>

                        <Link to="/main/misspellings" className="text-white  list-group-item list-group-item-action    p-1 text-center">
                            <Pencil className="d-block mx-auto my-1" size={40} />Misspellings</Link>

                        <Link to="/main/feeds" className="text-white  list-group-item list-group-item-action    p-1 text-center">
                            <Tag className="d-block mx-auto my-1" size={40} />Feeds</Link>

                        <Link to="/main/pages" className="text-white  list-group-item list-group-item-action    p-1 text-center">
                            <Collection className="d-block mx-auto my-1" size={40} />Pages</Link>
                        <Link to="/main/riskdomain" className="text-white  list-group-item list-group-item-action    p-1 text-center">
                            <ExclamationTriangle className="d-block mx-auto my-1" size={40} /> Risk Domain</Link>


                        <div className="btn-group dropright d-block">
                            <button type="button" className="text-white list-group-item list-group-item-action d-block dropdown-toggle p-1 text-center" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <Link45deg className="d-block mx-auto my-1" size={40} />  Links
                            </button>
                            <div className="dropdown-menu">
                                <Link className="dropdown-item btn-outline-success" to="/main/brokenlinks">
                                    <Link45deg className="mr-2" size={30} />Broken Links</Link>
                                <Link className="dropdown-item btn-outline-success" to="/main/ignoredlinks">
                                    <Link45deg className="mr-2" size={30} />Ignored Links</Link>
                                <Link className="dropdown-item btn-outline-success" to="/main/fixedlinks">
                                    <Link45deg className="mr-2" size={30} />Fixed Links</Link>
                                <Link className="dropdown-item btn-outline-success" to="/main/risklinks">
                                    <Link45deg className="mr-2" size={30} />Risk Links</Link>
                            </div>
                        </div>
                        <Link to="/main/settings" className=" text-white list-group-item list-group-item-action    p-1 text-center">
                            <Gear className="d-block mx-auto my-1" size={40} />Settings</Link>

                        <Link to="/main/upgrade" className="text-white list-group-item list-group-item-action    p-1 text-center">
                            <Bag className="d-block mx-auto my-1" size={40} />Upgrade</Link>
                    </div>
                </div>
            </div>


        </Fragment>

    )
}

export default Sidebar