import React, { useState } from "react"
import { accesibilityIssues } from "../helpers/utils";
export const SelectWebsite = ({data,setWebsiteUser})=>{
    const handleChange=(e)=>{
        setWebsiteUser(e.target.value);
        localStorage.setItem("websiteSelected",e.target.value)
        accesibilityIssues.map(issue=>{
            if(localStorage.getItem(issue.id)){
                localStorage.removeItem(issue.id)
            }
        })
    }
    return(
        <>
            <h4 className="text-secondary col-2 rounded">Your websites</h4>
            <select value={localStorage.getItem("websiteSelected") ? localStorage.getItem("websiteSelected") : "default"} onChange={(e)=>{handleChange(e)}} className="p-4 col-6">
                        <option disabled value="default">Select a website</option>
                        {
                            data.length > 0
                            ?
                            data.map(website=>{
                                 return(
                                     <option value={website.pk} name={website.pk} key={website.pk}>{website.start_url}</option>
                                 )
                            })
                            :
                            ""
                        }
            </select>
        </>
    )
}

