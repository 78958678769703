import React, { useState, useEffect } from 'react';
import Icon from '../img/icon2.png';
import { Link, useHistory } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { useForm } from 'react-hook-form'
import { Question, PersonPlus, ArrowReturnRight, PersonFill, KeyFill, Link45deg, ExclamationTriangle, Check2 } from 'react-bootstrap-icons';
import CountUp from 'react-countup';
import axiosInstance from '../helpers/axiosInstance';
import { customBasicMessageModal, customBasicMessageToast } from '../helpers/messages';

const LoginLeft = (props) => (
    <div className="col-xl-7 d-none d-xl-flex ">
        <div className="col  shadow-lg shadow-hover">
            <div className="text-center">
                <h2 className="text-muted mt-3 display-4">BYE BYE <span className="text-success">404</span></h2>
                <h3 className="text-muted text-uppercase display-6"> statistics</h3>
            </div>
            <div id="graph" className="card-body row ">
                <Doughnut data={props.data} options={props.options} legend={props.legend} />
                <div className="col-xl-4 col-12  mt-1">
                    <div className="card flex-row align-items-center align-items-stretch h-100 shadow-lg">
                        <div className="col-4 d-flex align-items-center bg-danger justify-content-center rounded-left"><Link45deg className=" " color="white" size={50} /></div>
                        <div className="col-8 py-1  bg-white rounded-right text-center">
                            <div className="h2 mt-0 text-muted"><CountUp end={props.broken ? props.abbrNum(props.broken, 2)[0] : 0} duration={4} separator=" " suffix={props.abbrNum(props.broken, 2)[1]} start={0} /></div>
                            <div className="text-muted">
                                <h6 className="text-muted">Broken Links </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-12 mt-1">
                    <div className="card flex-row align-items-center align-items-stretch h-100 shadow-lg">
                        <div className="col-4 d-flex align-items-center bg-warning justify-content-center rounded-left"><ExclamationTriangle className=" " color="white" size={50} /></div>
                        <div className="col-8 py-1  bg-white rounded-right text-center">
                            <div className="h2 mt-0 text-muted"><CountUp end={props.risk ? props.abbrNum(props.risk, 2)[0] : 0} duration={4} separator=" " suffix={props.abbrNum(props.risk, 2)[1]} start={0} /></div>
                            <div className="text-muted">
                                <h6 className="text-muted">Risk Links</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-12 mt-1">
                    <div className="card flex-row align-items-center align-items-stretch h-100 shadow-lg ">
                        <div className="col-4 d-flex align-items-center bg-success justify-content-center rounded-left "><Check2 className=" " color="white" size={50} /></div>
                        <div className="col-8 py-1  bg-white rounded-right text-center">
                            <div className="h2 mt-0 text-muted"><CountUp end={props.fixed ? props.abbrNum(props.fixed, 2)[0] : 0} duration={4} separator=" " suffix={props.abbrNum(props.fixed, 2)[1]} start={0} /></div>
                            <div className="text-muted">
                                <h6 className="text-muted">Fixed Links</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

const Login = () => {

    const { register, errors } = useForm();
    let history = useHistory();
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [links, setLinks] = useState([]);

    useEffect(() => {
        console.log("Login wait......->")
        document.title="Log in to Bye Bye 404"
        localStorage.clear()
        handleData()
    }, [])

    const handleData = async() => {
        try{
            const {data} = await axiosInstance.get("loginstatistics/")
            setLinks({
                broken: data.login_statistics.broken_links_count[0],
                risk: data.login_statistics.fixed_links_count[0],
                fixed: data.login_statistics.risk_links_count[0]
            })
        }catch(err){
            console.log("unauthorized user")
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        const dataUser = {
            username: user,
            password: password
        }
        try{
            const {data} = await axiosInstance.post("login/",dataUser)
            localStorage.setItem('token', data.access);
            localStorage.setItem('tokenRefresh', data.refresh);
            localStorage.setItem('user', data.user);
            localStorage.setItem('userId', data.userId);
            customBasicMessageToast(1500,"success","Login successfully")
            history.push("/main")
        }catch(err){
            console.log(err.response)
            customBasicMessageModal("error","Error","Incorrect email or password")
        }
    }

    const data = {
        labels: [
            'Fixed Links',
            'Broken links',
            'Risk links'
        ],
        datasets: [{

            data: [
                links.fixed ? links.fixed : 0,
                links.broken ? links.broken : 0,
                links.risk ? links.risk : 0
            ],
            backgroundColor: [
                '#28a745',
                '#dc3545',
                '#ffc107'
            ],

        }]
    };
    const options = {
        responsive: true,
        position: 'left'
    }

    const legend = {
        display: false,
        position: "left",
        fullWidth: true,
        reverse: false,
        labels: {
            fontSize: 15
        }

    }
    function abbrNum(number, decPlaces) {
        // 2 decimal places => 100, 3 => 1000, etc
        decPlaces = Math.pow(10, decPlaces);
        // Enumerate number abbreviations
        var abbrev = ["m", "b", "t"];

        // Go through the array backwards, so we do the largest first
        for (var i = abbrev.length - 1; i >= 0; i--) {
            // Convert array index to "1000", "1000000", etc
            var size = Math.pow(10, (i + 2) * 3);
            // If the number is bigger or equal do the abbreviation
            if (size <= number) {
                // Here, we multiply by decPlaces, round, and then divide by decPlaces.
                // This gives us nice rounding to a particular decimal place.
                number = Math.round(number * decPlaces / size) / decPlaces;

                // Handle special case where we round up to the next abbreviation
                if ((number === 1000) && (i < abbrev.length - 1)) {
                    number = 1;
                    i++;
                }

                break;
            }
        }

        return [number, abbrev[i]];
    }

    return (

        <>
            <div className="container-login container-fluid mt-2 app-container">
                <div className="row justify-content-center ">

                    <div className="col-sm-11 col-md-9 col-lg-10 col-xl-10 pt-sm-3  my-lg-5 mt-md-5 mt-lg-0 ">
                        <div className="shadow-sm shadow-hover card p-none rounded-lg ">
                            <div className="card-body p-0 m-0 ">
                                <div className="row ">
                                    <LoginLeft data={data} options={options} legend={legend}
                                        broken={links.broken}
                                        risk={links.risk}
                                        fixed={links.fixed}
                                        abbrNum={abbrNum}></LoginLeft>
                                    <div className="col-xl-5 shadow-muted  px-5 px-xl-0 mt-xl-5">
                                        <div className="px-xl-5 pt-xl-2">
                                            <h1 className='text-center text-secondary'>Log in to Bye Bye 404</h1>
                                            <div className="col-12 user-img p-xl-3 ">
                                                <img src={Icon} alt="Logo bye bye 404" className="d-block mx-auto "></img>
                                            </div>
                                            <form className="row user py-3 " onSubmit={handleSubmit}>
                                                <div className="col-1 py-1 mr-1">
                                                    <PersonFill className=" " color="black" size={40} />
                                                </div>
                                                <div className="col-10 py-1 pl-4">
                                                    <input className="form-control form-control-user "
                                                        type="email"
                                                        id="Username"
                                                        title="Email"
                                                        placeholder="Email"
                                                        name="username"
                                                        onChange={event => { setUser(event.target.value) }}
                                                        ref={register({
                                                            required: {
                                                                value: true,
                                                                message: 'Email required'
                                                            },
                                                            maxLength: {
                                                                value: 100,
                                                                message: 'No more than 100 characters!'
                                                            },
                                    
                                                        })}
                                                        required />
                                                    <span className="text-danger text-small d-block mb-2 ml-5 pl-lg-3">
                                                        {errors?.username?.message}
                                                    </span>
                                                </div>
                                                <div className="col-1 py-1 mr-1">
                                                    <KeyFill className=" " color="black" size={40} />
                                                </div>
                                                <div className="col-10 py-1 pl-4">
                                                    <input className="form-control form-control-user"
                                                        type="password"
                                                        id="PasswordInput"
                                                        placeholder="Password"
                                                        name="password"
                                                        onChange={event => { setPassword(event.target.value) }}
                                                        ref={register({
                                                            required: {
                                                                value: true,
                                                                message: 'Password required'
                                                            },
                                                            maxLength: {
                                                                value: 15,
                                                                message: 'No more than 15 characters!'
                                                            },
                                                            minLength: {
                                                                value: 8,
                                                                message: 'Minimum 8 characters'
                                                            },
                                                        })}
                                                        required />
                                                    <span className="text-danger text-small d-block mb-2 ml-5 pl-lg-3">
                                                        {errors?.password?.message}
                                                    </span>
                                                </div>
                                                <div className="form-group col-11 py-1 ml-2 ">
                                                    <button className=" btn btn-success btn-block text-white btn-user " type="submit">
                                                        <ArrowReturnRight className="mr-2 " color="white" size={20} />Log in</button>
                                                </div>
                                            </form>
                                            <hr className="col-11 "></hr>
                                            <div className="row forgot mb-3 ">
                                                <div className="col-6 ">
                                                    <Link to="/password-recovery" className="btn btn-outline-success col-12 ">
                                                        <Question className="mr-1 " color="green" size={30} />Forgot Password </Link>
                                                </div>
                                                <div className="col-6">
                                                    <Link to="/signup" className="btn btn-outline-success col-12 ">
                                                        <PersonPlus className="mr-1" color="green" size={30} />Create account </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" footer-login text-center bg-dark py-2 pb-5">
                <div className="footer-copyright   text-center text-white">© {new Date().getFullYear()} Copyright
                                <a className="text-white" href="https://www.encisosystems.com/">
                        <img className="ml-3" alt="Logo enciso system" href="https://www.encisosystems.com/" src={process.env.PUBLIC_URL + '/Logo-white-30x87.png'} />
                    </a>
                </div>
            </div>


        </>
    );
}

export default Login;