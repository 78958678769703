import React, { useEffect, useMemo, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { Check2, EyeSlashFill, Plus } from 'react-bootstrap-icons';
import { useModal, ModalProvider } from "react-modal-hook";
import ReactModal from 'react-modal';
import { Pencil } from 'react-bootstrap-icons';
import { customStyles } from '../helpers/tableStyles';
import FilterComponent from './FilterComponent';
import { axiosUserLog } from '../helpers/axiosInstance';
import {handleGetData} from '../helpers/requests'
import { changeDate } from '../helpers/utils';
import { customBasicMessageModal, customBasicMessageToast, customConfirmMessage } from '../helpers/messages';
import { SelectWebsite } from './SelectWebsite';
import TableLoader from './TableLoader';
import { Helmet } from 'react-helmet';

const Create = (props) => {
    const [hintProxy, setHintProxy] = useState(null);
    useEffect(() => {
        if (props.row.hint_proxy) {
            axiosUserLog().get(`hints/${props.row.hint_proxy}/`).then(response => {
                setHintProxy(response.data.fixed_url);
            });
        } else {
            
        }
        
    }, [])
    const websiteRef = useRef(null);
    const [showModal, hideModal] = useModal(() => (

        <ReactModal isOpen className="p-3 mx-auto bg-white border col-10 col-md-5 mt-lg-5" ariaHideApp={false}>
            <form className="row" onSubmit={(e)=>{
                e.preventDefault()
                hideModal();
                props.create(props.row, websiteRef.current.value);
            }}>
                <div className="col-12 form-group">

                    <button type="button" className="close" aria-label="Close" onClick={hideModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h3 className="text-center text-secondary" >{(props.row.hint_proxy) ? 'Edit hint' : 'Add hint'}</h3>
                </div>
                <div className="col-12 form-group " >
                    <label >Hint:</label>
                    <input type="url" ref={websiteRef} className="form-control" id="inputWebsite" placeholder="Enter Hint" name="website" defaultValue={(hintProxy == null) ? '' : hintProxy} required />
                </div>

                <div className="col-6 small">
                    <button type="button" className="btn btn-secondary col-12 " onClick={hideModal}>Close</button>
                </div>
                <div className="col-6 small">
                    <button type="submit" className="btn btn-success col-12 ">Save</button>
                </div>
            </form>
        </ReactModal>
    ), [hintProxy]);

    return (
        <div className="input-group">
            <textarea aria-label="With textarea" id="hintArea" rows="1" className="form-control" value={hintProxy ? hintProxy : 'No hint'} disabled></textarea >
            <div className="input-group-append">
                <button onClick={showModal} className={props.className} >{(props.row.hint_proxy) ? <Pencil className="" size={20} /> : <Plus className="" size={20} />}</button>
                <button className="btn btn-outline-danger" type="button" onClick={() => { props.delete(props.row.hint_proxy) }}>X</button>
            </div>
        </div>

    );
};

const TableRiskLinks = () => {
    let history = useHistory();
    const columns = [

        {
            cell: row => <p className="mt-3">{changeDate(row.created)}</p>,
            name: 'Detected',
            selector: 'created',
            grow: 0.2
        },

        {
            cell: row => <a href={row.page.url} className="mt-3">{row.page.url}</a>,
            name: 'Page',
        },
        {
            cell: row => <a href={row.url} className="mt-3">{row.url}</a>,
            name: 'Risk Link',
            selector: 'url',
        },
        {
            cell: row =>
                <ModalProvider><Create
                    icon="action icon ion-md-create"
                    className="btn btn-outline-secondary "
                    row={row}
                    create={row.hint_proxy ? handleUpdate : handleCreate}
                    delete={handleDeleteOne} />
                </ModalProvider>,
            name: 'Hints',
            selector: 'hint_proxy',
            grow: 1
        },
        {
            name: 'Actions',
            cell: row => <div>
                <button type="button" title="Fixed" className="p-1 ml-1 btn btn-success text-white " onClick={() => setFixed(row.id, row.url)}>
                    <Check2 className="" size={25} /></button>
                <button type="button" title="Ignore" className="p-1 ml-1 btn btn-secondary text-white " onClick={() => setIgnored(row.id, row.url)}>
                    <EyeSlashFill className="" size={25} /></button></div>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            grow: 3
        },
    ];

    const [dataApi, setDataApi] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = (dataApi.length>0) ? dataApi.filter(item => item.url && item.url.toLowerCase().includes(filterText.toLowerCase())) : [];
    const [selectedWebsites, setSelectedWebsites] = useState([]);
    const[websiteUser,setWebsiteUser] = useState(localStorage.getItem("websiteSelected") ? localStorage.getItem("websiteSelected") : "")
    const[pendingLoading,setPendingLoading]=useState(true)
    
    useEffect(() => {
        setPendingLoading(true)
        handleData()
    }, [selectedWebsites.length,websiteUser])

    const handleWebsite = async() => {
        try{
            const {data:dataWebsites} = await axiosUserLog().get("websites/?website=pk,start_url&limit=100");
            setSelectedWebsites(dataWebsites.results)
        }catch(err){
            console.log(err)
        }
    };
    const handleData = async () => {
        try{
            if(websiteUser!=""){
                const {data} = await handleGetData("risklinks/?link=id,url,created,hint_proxy,page&page=id,url,website&website=pk,start_url&limit=100", history)
                setDataApi(data.results.filter(rlink=>rlink.page.website.pk==websiteUser))
                setPendingLoading(false)
            }
            await handleWebsite()
        }catch(err){
            console.log(err)
        }
    }
    const handleCreate = async(row, url) => {
        try{
            await axiosUserLog().post("hints/",
                {
                    "website": row.page.website.pk,
                    "fixed_url": url,
                    "broken_url": row.url,
                }
            )
            customBasicMessageToast(1500,"success","Hint successfully created")
            await handleData()
        }catch(err){
            customBasicMessageModal("error","There was an error!","Please re-check your form and try again")
            await handleData()
        }
    };
    const handleUpdate = async (row, url) => {
        try {
            await axiosUserLog().patch(`hints/${row.hint_proxy}/`, {
                "fixed_url": url,
            })
            customBasicMessageToast(1500,"success","Hint successfully updated")
            await handleData()
        } catch (error) {
            customBasicMessageModal("error","There was an error!","Please re-check your form and try again")
            await handleData()
        }
    };
    const setFixed = async (pk, url) => {
        customConfirmMessage("warning",`Are you sure to move the Link (${url}) to fixed links?`,"",async()=>{
            try{
                await axiosUserLog().patch("fixedlinks/", { "id": pk, "fixed": 'True' })
                customBasicMessageToast(1500,"success","Link moved to fixed links!")
                await handleData()
            }catch(error){
                if (error.response.status === 400) {
                    customBasicMessageModal("error","There was an error!","The link could not be moved! Please try again")
                }
                if (error.response.status === 401) {
                    customBasicMessageModal("error","There was an error!","Try again!")
                    await handleData()
                }
            }
        } )
    }
    const handleDeleteOne = async pk => {
        customConfirmMessage("warning","Are you sure you want to delete?","This action cannot be undone",async()=>{
            await axiosUserLog().delete(`hints/${pk}/`);
            await handleData()
        })
    }
    const setIgnored = async (pk, url) => {
        customConfirmMessage("warning",`Are you sure to move the Link (${url}) to ignored links?`,"",async()=>{
            try{
                await axiosUserLog().patch(`risklinks/${pk}/`, { ignored: true })
                customBasicMessageToast(1500,"success","Link moved to ignored links!");
                await handleData()
            }catch(error){
                if (error.response.status === 400) {
                    customBasicMessageModal("error","There was an error!","The link could not be moved! Please try again")
                }
                if (error.response.status === 401) {
                    customBasicMessageModal("error","There was an error!","Please try again")
                    await handleData()
                }
            }
        })
    }
    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
        return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder="Filter by Risk Link"/>;
    }, [filterText, resetPaginationToggle]);

    return (
        <div id="content" className="p-md-5 container-fluid">
            <Helmet>
                <title>Risk Links</title>
            </Helmet>
            <div className="d-flex flex-column" id="content-wrapper">
                <div className="row gradient-card-header  py-2 mx-4 mb-3 d-flex">
                    <SelectWebsite data={selectedWebsites} setWebsiteUser={setWebsiteUser}/>
                </div>
                <div className="card card-cascade narrower  border-0 ">
                    <div className="row gradient-card-header  py-2 mx-4 mb-3 d-flex d-block">
                        <h1 className="col-12  text-secondary   col-6 rounded">Risk Links</h1>
                        <h6 className="col-12  text-secondary  bg-light-blue p-3 col-6 rounded">
                            Your Risk Links.
                            <br></br>For your convenience, only the first 100 or less Risk Links are shown.
                        </h6>
                    </div>
                    <div className="row  px-4">
                    </div>
                    <div className="px-4">
                        {
                            websiteUser!=""
                            ?
                            <DataTable
                                columns={columns}
                                data={filteredItems}
                                keyField={'pk'}
                                highlightOnHover
                                pointerOnHover
                                customStyles={customStyles}
                                pagination
                                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                                persistTableHead
                                progressPending={pendingLoading}
                                progressComponent={<TableLoader/>}
                            />
                            :
                            <h4 className="text-center">Select a website to continue</h4>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TableRiskLinks;