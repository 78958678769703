import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import registerServiceWorker from './registerServiceWorker';
import 'sweetalert2/dist/sweetalert2.min.css';
import { ModalProvider } from "react-modal-hook";

ReactDOM.render(<ModalProvider><App /></ModalProvider>, document.getElementById('root'));
registerServiceWorker();
