import React, { Fragment, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import { Globe2, Collection, Link45deg, ExclamationTriangle, InfoCircle, EyeSlash, Check2 } from 'react-bootstrap-icons';
import CountUp from 'react-countup';
import { useHistory } from 'react-router-dom';
import {handleGetData} from "../helpers/requests"
import { customConfirmMessage } from '../helpers/messages';
import WebPageViewerErrors from './WebPageViewerErrors';
const PageContent = () => {
  let use_history = useHistory();
  const [links, setLinks] = useState({});
  const [history, setHistory] = useState({});
  useEffect(() => {
    handleData()
    handleHistory()
  }, [])

  const handleData = async() => {
    try{
      const {data} = await handleGetData("accountstatistics/",use_history)
      setLinks(
        {
          broken: data.link_statistics.broken_links_count[0],
          fixed: data.link_statistics.fixed_links_count[0],
          risk: data.link_statistics.risk_links_count[0],
          ignored: data.link_statistics.ignored_links_count[0],
          pages: data.link_statistics.pages_count[0],
          websites: data.link_statistics.websites_count[0],
        });
        if(data.link_statistics.websites_count[0]==0){
          customConfirmMessage("info","It looks like you have no websites registered in your account.","Click accept to add your first website.",()=>{
            use_history.push("main/websites")
          })  
        }
    }catch(err){
      console.log(err)
    }
  }

  const handleHistory = async() => {
    try{
      const {data} = await handleGetData("monthlystatistics/", use_history)
      setHistory(
        {
          january: (data.monthly_statistics.January.length === 0) ? [null, null, null, null] : data.monthly_statistics.January,
          february: (data.monthly_statistics.February.length === 0) ? [null, null, null, null] : data.monthly_statistics.February,
          march: (data.monthly_statistics.March.length === 0) ? [null, null, null, null] : data.monthly_statistics.March,
          april: (data.monthly_statistics.April.length === 0) ? [null, null, null, null] : data.monthly_statistics.April,
          may: (data.monthly_statistics.May.length === 0) ? [null, null, null, null] : data.monthly_statistics.May,
          june: (data.monthly_statistics.June.length === 0) ? [null, null, null, null] : data.monthly_statistics.June,
          july: (data.monthly_statistics.July.length === 0) ? [null, null, null, null] : data.monthly_statistics.July,
          august: (data.monthly_statistics.August.length === 0) ? [null, null, null, null] : data.monthly_statistics.August,
          september: (data.monthly_statistics.September.length === 0) ? [null, null, null, null] : data.monthly_statistics.September,
          october: (data.monthly_statistics.October.length === 0) ? [null, null, null, null] : data.monthly_statistics.October,
          november: (data.monthly_statistics.November.length === 0) ? [null, null, null, null] : data.monthly_statistics.November,
          december: (data.monthly_statistics.December.length === 0) ? [null, null, null, null] : data.monthly_statistics.December,
        }
      );
    }catch(err){
      console.log(err)
    }
  }

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    datasets: [{
      label: 'Broken links',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'black',
      borderColor: 'red',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'red',
      pointBackgroundColor: 'red',
      pointBorderWidth: 5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'black',
      pointHoverBorderColor: 'black',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,        
      data:
        [
          history.january ? history.january[0] : null,
          history.february ? history.february[0] : null,
          history.march ? history.march[0] : null,
          history.april ? history.april[0] : null,
          history.may ? history.may[0] : null,
          history.june ? history.june[0] : null,
          history.july ? history.july[0] : null,
          history.august ? history.august[0] : null,
          history.september ? history.september[0] : null,
          history.october ? history.october[0] : null,
          history.november ? history.november[0] : null,
          history.december ? history.december[0] : null
        ]

    }, {
      label: 'Fixed links',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'black',
      borderColor: 'green',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'green',
      pointBackgroundColor: 'green',
      pointBorderWidth: 5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'black',
      pointHoverBorderColor: 'black',
      pointHoverBorderWidth: 3,
      pointRadius: 1,
      pointHitRadius: 10,
      data:
      [
        history.january ? history.january[1] : null,
        history.february ? history.february[1] : null,
        history.march ? history.march[1] : null,
        history.april ? history.april[1] : null,
        history.may ? history.may[1] : null,
        history.june ? history.june[1] : null,
        history.july ? history.july[1] : null,
        history.august ? history.august[1] : null,
        history.september ? history.september[1] : null,
        history.october ? history.october[1] : null,
        history.november ? history.november[1] : null,
        history.december ? history.december[1] : null
      ]
    }, {
      label: 'Ignored links',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'black',
      borderColor: 'gray',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'gray',
      pointBorderColor: 'gray',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'black',
      pointHoverBorderColor: 'black',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data:
      [
        history.january ? history.january[3] : null,
        history.february ? history.february[3] : null,
        history.march ? history.march[3] : null,
        history.april ? history.april[3] : null,
        history.may ? history.may[3] : null,
        history.june ? history.june[3] : null,
        history.july ? history.july[3] : null,
        history.august ? history.august[3] : null,
        history.september ? history.september[3] : null,
        history.october ? history.october[3] : null,
        history.november ? history.november[3] : null,
        history.december ? history.december[3] : null
      ]
    }, {
      label: 'Risk links',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'black',
      borderColor: 'rgba(243,218,011)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(243,218,011)',
      pointBackgroundColor: 'rgba(243,218,011)',
      pointBorderWidth: 5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'black',
      pointHoverBorderColor: 'black',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data:
      [
        history.january ? history.january[2] : null,
        history.february ? history.february[2] : null,
        history.march ? history.march[2] : null,
        history.april ? history.april[2] : null,
        history.may ? history.may[2] : null,
        history.june ? history.june[2] : null,
        history.july ? history.july[2] : null,
        history.august ? history.august[2] : null,
        history.september ? history.september[2] : null,
        history.october ? history.october[2] : null,
        history.november ? history.november[2] : null,
        history.december ? history.december[2] : null
      ]

    }]
  };
  const options = {
    responsive: true,
  }

  const data2 = {
    labels: [
      'Broken links',
      'Risk links',
      'Ignored links',
      'Fixed links'
    ],
    datasets: [{
      data: [links.broken, links.risk, links.ignored, links.fixed],
      backgroundColor: [
        'red',
        'rgba(243,218,011)',
        'gray',
        'green'
      ],

    }]
  };
  const options2 = {
    responsive: true,
    position: 'left'
  }
  const legend = {
    display: true,
    position: "bottom",
    fullWidth: true,
    reverse: false,
    labels: {
      fontSize: 15
    }

  }
  const legend2 = {
    display: true,
    position: "left",
    fullWidth: true,
    reverse: false,
    labels: {
      fontSize: 15
    }

  }
  return (
    <Fragment>
      <div id="content" className="container-fluid py-2 px-5">
        <section className="py-3">
          <div className="row ">
            <div className="col-xl-2 col-sm-6 my-2">
              <div className="card-info  border-0 shadow-sm shadow-hover shadow">
                <div className="card-body d-flex">
                  <div>
                    <div className="circle rounded-circle  align-self-center d-flex mr-3 bg-success">
                      <h2 className="align-self-center mx-auto text-white pt-2"><Globe2 className="d-block mx-auto my-1" size={30} /></h2>
                    </div>
                  </div>
                  <div className="align-self-center">
                    <h4 className="mb-0 text-dark "><CountUp end={links.websites ? links.websites : 0} duration={3} separator=" " /></h4>
                    <h5 className="text-muted">Websites</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-sm-6 my-2">
              <div className="card-info shadow-sm border-0 shadow-hover shadow">
                <div className="card-body d-flex">
                  <div>
                    <div className="circle rounded-circle  align-self-center d-flex mr-3 bg-success">
                      <h2 className="align-self-center mx-auto text-white pt-2"><Collection className="d-block mx-auto my-1" size={30} /></h2>
                    </div>
                  </div>
                  <div className="align-self-center">
                    <h4 className="mb-0 text-dark"><CountUp end={links.pages ? links.pages : 0} duration={3} separator=" " /></h4>
                    <h5 className="text-muted">Pages</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-sm-6 my-2">
              <div className="card-info shadow-sm border-0 shadow-hover shadow">
                <div className="card-body d-flex">
                  <div>
                    <div className="circle rounded-circle  align-self-center d-flex mr-3 bg-success">
                      <h2 className="align-self-center mx-auto text-white pt-2"><Link45deg className="d-block mx-auto my-1" size={30} /></h2>
                    </div>
                  </div>
                  <div className="align-self-center">
                    <h4 className="mb-0 text-dark"><CountUp end={links.broken ? links.broken : 0} duration={3} separator=" " /></h4>
                    <h5 className="text-muted">Broken Links</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-sm-6 my-2">
              <div className="card-info shadow-sm border-0 shadow-hover shadow">
                <div className="card-body d-flex">
                  <div>
                    <div className="circle rounded-circle  align-self-center d-flex mr-3 bg-success">
                      <h2 className="align-self-center mx-auto text-white pt-2"><ExclamationTriangle className="d-block mx-auto my-1" size={30} /></h2>
                    </div>
                  </div>
                  <div className="align-self-center">
                    <h4 className="mb-0 text-dark"><CountUp end={links.risk ? links.risk : 0} duration={3} separator=" " /></h4>
                    <h5 className="text-muted">Risk Links</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-sm-6 my-2">
              <div className="card-info shadow-sm border-0 shadow-hover shadow">
                <div className="card-body d-flex">
                  <div>
                    <div className="circle rounded-circle  align-self-center d-flex mr-3 bg-success">
                      <h2 className="align-self-center mx-auto text-white pt-2"><EyeSlash className="d-block mx-auto my-1" size={30} /></h2>
                    </div>
                  </div>
                  <div className="align-self-center">
                    <h4 className="mb-0 text-dark"><CountUp end={links.ignored ? links.ignored : 0} duration={3} separator=" " /></h4>
                    <h5 className="text-muted">Ignored Links</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-sm-6 my-2">
              <div className="card-info shadow-sm border-0 shadow-hover shadow">
                <div className="card-body d-flex">
                  <div>
                    <div className="circle rounded-circle  align-self-center d-flex mr-3 bg-success">
                      <h2 className="align-self-center mx-auto text-white pt-2"><Check2 className="d-block mx-auto my-1" size={30} /></h2>
                    </div>
                  </div>
                  <div className="align-self-center">
                    <h4 className="mb-0 text-dark"><CountUp end={links.fixed ? links.fixed : 0} duration={3} separator=" " /></h4>
                    <h5 className="text-muted">Fixed Links</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="row justify-content-center text-center mt-5">
          <div className="card text-center  col-10 col-xl-5 mx-3 my-2  shadow">
            <div className="card-header bg-white">
              <h2 className="my-0 font-weight-normal text-muted">Links in the course of {new Date().getFullYear()} </h2>
            </div>
            <div className="card-body">
              <Line data={data} options={options} legend={legend} />
            </div>
            <span className="text-muted my-2"><InfoCircle className="mr-1" size={22} />To hide the history of a link type, click the name.</span>
          </div>
          <div className="card text-center col-10 col-xl-5 mx-3 my-2 shadow">
            <div className="card-header bg-white">
              <h2 className="my-0 font-weight-normal text-muted">Links in the account</h2>
            </div>
            <div className="card-body">
              <Doughnut data={data2} options={options2} legend={legend2} />
            </div>
            <span className="text-muted my-2"><InfoCircle className="mr-1" size={22} />To hide a type of link, click on the name.</span>
          </div>
        </section>
      </div>

      <WebPageViewerErrors/>
    </Fragment>

  );
}

export default PageContent;