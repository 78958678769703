import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import differenceBy from 'lodash/differenceBy';
import DataTable from 'react-data-table-component';
import ReactModal from 'react-modal';
import { useModal, ModalProvider } from "react-modal-hook";
import { Link, useHistory } from 'react-router-dom';
import { Trash, Pencil } from 'react-bootstrap-icons';
import FilterComponent from './FilterComponent';
import { customStyles } from '../helpers/tableStyles';
import { axiosUserLog } from '../helpers/axiosInstance';
import { handleGetData } from '../helpers/requests';
import { customBasicMessageModal, customBasicMessageToast, customConfirmMessage } from '../helpers/messages';
import { SelectWebsite } from './SelectWebsite';
import TableLoader from './TableLoader';
import { Helmet } from 'react-helmet';

const Create = (props) => {
    const websiteRef = useRef(null);
    const urlRef = useRef(null);
    const urlHome = useRef(null);

    const [showModal, hideModal] = useModal(() => (
        <ReactModal isOpen className="p-3 mx-auto bg-white border col-10 col-md-5 mt-lg-5" ariaHideApp={false}>
            <form className="row" onSubmit={(e) => {
                e.preventDefault()
                hideModal();
                props.create(props.row, websiteRef.current.value, urlRef.current.value, urlHome.current.checked);
            }}>
                <div className="col-12 form-group">

                    <button type="button" className="close" aria-label="Close" onClick={hideModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h3 className="text-center text-secondary" >{props.header}</h3>
                </div>
                <div className="col-12 form-group">
                    <label >Website:</label>
                    <select ref={websiteRef} className="form-control" defaultValue={props.row ? props.row.website.pk : 'DEFAULT'}>
                        {(props.row) ? '' : <option key="0" value="DEFAULT">Choose</option>}

                        {props.selectedWebsites.map(element => (
                            <option key={element.pk} value={element.pk} >{element.start_url}</option>
                        ))}
                    </select>
                </div>
                <div className="col-12 form-group">
                    <label >Url:</label>
                    <input type="url" ref={urlRef} className="form-control" id="inputUrl" placeholder="Enter Url" name="url" defaultValue={(props.row) ? props.row.url : ''} required />
                </div>
                <div className="col-12 form-group  ">
                    <label className="col" htmlFor="inputHome">Home:</label>
                    <input type="checkbox" ref={urlHome} className="form-control form-control-sm col-1" id="inputHome" name="home" defaultChecked={(props.row) ? props.row.home_page : ''} />
                </div>

                <div className="col-6 small">
                    <button type="button" className="btn btn-secondary col-12 " onClick={hideModal}>Close</button>

                </div>
                <div className="col-6 small">
                    <button type="submit" className="btn btn-success col-12 " >Save</button>

                </div>
            </form>
        </ReactModal>
    ), [props]);

    return <button onClick={showModal} className={props.className} >{(props.title) ? 'Add Page' : <Pencil className="" size={25} />}</button>;
};

const TablePage = () => {

    let history = useHistory();

    const columns = [
        {
            cell: row => <a href={row.url} className="mt-3">{row.url}</a>,
            name: 'Page',
            selector: 'url',
            sortable: true,
        },
        {
            cell: row => <p className="mt-3">{row.broken_links}</p>,
            name: 'Broken links',
            sortable: true,
            grow: 0.3
        },
        {
            name: 'Actions',
            cell: row => <div><ModalProvider><Create icon="action icon ion-md-create" selectedWebsites={selectedWebsites} header="Edit Page" className="p-1 ml-1 btn btn-secondary " row={row} create={handleUpdate} /></ModalProvider>
                <button type="button" title="Delete" className="p-1 ml-1 btn btn-danger text-white " onClick={() => handleDeleteOne(row)}>
                    <Trash className="" size={25} /></button></div>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedWebsites, setSelectedWebsites] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const[websiteUser,setWebsiteUser] = useState(localStorage.getItem("websiteSelected") ? localStorage.getItem("websiteSelected") : "")
    const [data, setData] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = (data.length>0) ? data.filter(item => item.url && item.url.toLowerCase().includes(filterText.toLowerCase())) : [];
    const[pendingLoading,setPendingLoading]=useState(true)
    useEffect(() => {
        setPendingLoading(true)
        handleData()
    }, [selectedWebsites.length,websiteUser])

    const handleWebsite = async() => {
        try{
            const {data:dataWebsites} = await axiosUserLog().get("websites/?website=pk,start_url&limit=100");
            setSelectedWebsites(dataWebsites.results)
        }catch(err){
            console.log(err)
        }
    };

    const handleData = async() => {  
        try{
            if(websiteUser!=""){
                const {data} = await handleGetData(`pages/?idWebsite=${websiteUser}&page=id,url,home_page,website,broken_links&website=pk,start_url`, history);
                setData(data.filter(p=>p.website.pk==websiteUser))
                setPendingLoading(false)
            }
            await handleWebsite()
        }catch(err){
            console.log(err)
        }
    }

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const contextActions = useMemo(() => {
        const handleDelete = async () => {
            customConfirmMessage("warning","Are you sure you want to delete:",`${selectedRows.map(r => r.url)}?`,async()=>{
                setToggleCleared(!toggleCleared);           
                for (let i = 0; i < selectedRows.length; i++) {
                    await axiosUserLog().delete(`pages/${selectedRows[i].id}/`);
                }
                customBasicMessageToast(1500,"success","Pages successfully removed")
                setData(differenceBy(data, selectedRows, 'id'))
            })
        };

        return <Link to="/main/pages" key="delete" className="btn btn-danger" onClick={handleDelete} >Delete</Link>;
    }, [data, selectedRows, toggleCleared]);

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
        return <FilterComponent placeholder="Filter by page" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    const handleCreate = async (row, website, url, home) => {
        try {
            await axiosUserLog().post("pages/", {
                "url": url,
                "website": website,
                "home_page": home,
            })
            customBasicMessageToast(1500,"success","Page successfully created")
            await handleData()
        } catch (error) {
            console.log({error})
            if (error.response.status === 400) {
                customBasicMessageModal("error","There was an error!", error?.response?.data?.error ? error.response.data.error : "There was an error!","Please try again")
            }
            if (error.response.status === 401) {
                customBasicMessageModal("error","There was an error!","Please try again")
                await handleData()
            }
        }
    };

    const handleUpdate = async (row, website, url, home) => {
        try {
            await axiosUserLog().put(`pages/${row.id}/`, {
                "url": url,
                "website": website,
                "home_page": home,
            })
            customBasicMessageToast(1500,"success","Page successfully updated")
            await handleData()
        } catch (error) {
            if (error.response.status === 400) {
                customBasicMessageModal("error","There was an error!","Please re-check your form and try again")
            }
            if (error.response.status === 401) {
                customBasicMessageModal("error","There was an error!","Please try again")
                await handleData()
            }
        }
    };

    const handleDeleteOne = async row => {
        customConfirmMessage("warning",`Are you sure you want to delete: ${row.url}?`,"",async()=>{
            try {
                await axiosUserLog().delete(`pages/${row.id}/`);
                customBasicMessageToast(1500,"success","Page successfully removed")
                setData(data.filter(domain => domain.id !== row.id))
            } catch (error) {
                if (error.response.status === 400) {
                    customBasicMessageModal("error","There was an error!","Please re-check your form and try again")
                }
                if (error.response.status === 401) {
                    customBasicMessageModal("error","There was an error!","Please try again")
                    await handleData()
                }
            }
        })
    }
    console.log(selectedWebsites)
    const actions = <ModalProvider><Create title="Page" selectedWebsites={selectedWebsites} icon="action icon ion-md-add pr-1" className=" text-center btn btn-success " header="Add Page" create={handleCreate} /></ModalProvider>;
    return (
        <>
            <Helmet>
                <title>Pages</title>
            </Helmet>
            <div id="content" className="p-md-5 container-fluid ">
                <div className="d-flex flex-column" id="content-wrapper">
                    <div className="row gradient-card-header  py-2 mx-4 mb-3 d-flex">
                        <SelectWebsite data={selectedWebsites} setWebsiteUser={setWebsiteUser}/>
                    </div>
                    <div className="card card-cascade narrower  border-0 ">
                        <div className="row gradient-card-header  py-2 mx-4 mb-3 d-flex d-block">
                            <h1 className="col-12  text-secondary   col-6 rounded">Pages</h1>
                            <h6 className="col-12  text-secondary  bg-light-blue p-3 col-6 rounded">Your Pages.</h6>
                        </div>
                        <div className="row  px-4">

                        </div>
                        <div className="px-4">
                            {
                                websiteUser!=""
                                ?
                                <DataTable
                                    columns={columns}
                                    data={filteredItems}
                                    keyField={'pk'}
                                    highlightOnHover
                                    pointerOnHover
                                    customStyles={customStyles}
                                    pagination
                                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                    subHeader
                                    subHeaderComponent={subHeaderComponentMemo}
                                    selectableRows
                                    persistTableHead
                                    actions={actions}
                                    contextActions={contextActions}
                                    onSelectedRowsChange={handleRowSelected}
                                    clearSelectedRows={toggleCleared}
                                    progressPending={pendingLoading}
                                    progressComponent={<TableLoader/>}
                                />
                                :
                                <h4 className="text-center">Select a website to continue</h4>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>

    );

}

export default TablePage;