import React from "react"
import { Link } from "react-router-dom"


const Header = (props) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark ">
      <div className="container-fluid">
        <button className="btn btn-outline-success  navbar-brand" id="toggle" onClick={props.click}>&#8801;</button>
        <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse " id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto text-center text-white">
            <li className="nav-item ">
              <Link className="nav-link text-white" to="/">Help</Link>
            </li>
            <li className="nav-item dropdown">
              <button className="nav-link mx-auto dropdown-toggle btn btn-dark text-white" href="#" id="navbarDropdown" 
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {localStorage.getItem('user')}
                </button>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <Link to="/main/accountinfo" className="dropdown-item btn-outline-success" href="#">Account</Link>
                <div className="dropdown-divider"></div>
                <Link to="/logout" className="dropdown-item" >Log out</Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header