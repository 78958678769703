import React, { useEffect, useMemo, useState } from "react"
import DataTable from "react-data-table-component"
import { useHistory, useParams } from "react-router"
import { Link } from "react-router-dom"
import { handleGetData } from "../helpers/requests"
import { customStyles } from "../helpers/tableStyles"
import { accesibilityIssues, searchAttributeIssue, searchNameIssue } from "../helpers/utils"
import FilterComponent from "./FilterComponent"
import TableLoader from "./TableLoader"
import { Helmet } from "react-helmet"
import CsvButton from "./CsvButton"
const IssueAccesibilityDetails=()=>{
    const {issue,idWebsite} = useParams()
    console.log(issue)
    const history = useHistory()
    const [dataWebsite,setDataWebsite]=useState([])
    const [pendingLoading,setPendingLoading]=useState(true)
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [nameWebsite,setNameWebsite]=useState("")
    const filteredItems = (dataWebsite.length>0) ? dataWebsite.filter(item => item.url && item.url.toLowerCase().includes(filterText.toLowerCase())) : [];
    const filteredItems2 = (dataWebsite.length>0) ? dataWebsite.filter(item => item.url && item.url.toLowerCase().includes(filterText.toLowerCase())).map(item => {
        if(item[issue]>0){
            console.log(item)
            return (
                {
                    url:item['url'],
                    issue:item[issue],
                    website:item?.website?.start_url,

                })
        }
    }) : [];
    useEffect(()=>{
        if(localStorage.getItem(issue)){
            const pagesStorage = JSON.parse(localStorage.getItem(issue))
            setNameWebsite(pagesStorage[0].website.start_url)
            setDataWebsite(pagesStorage)
            setPendingLoading(false) 
        }else{
            handleData()
        }
    },[])
    const handleData = async() => {
        try{
            const {data} = await handleGetData(`pages/?idWebsite=${idWebsite}&page=id,image_without_alt,iframe_without_alt,title,buttons_without_alt,links_without_alt,url,website&website=start_url`,history)
            console.log(data)
            const pagesWebsite = data.filter(page=>page[issue]!==0)
            console.log(pagesWebsite)
            localStorage.setItem(issue,JSON.stringify(pagesWebsite))
            setNameWebsite(pagesWebsite[0].website.start_url)
            setDataWebsite(pagesWebsite)
            setPendingLoading(false)    
        }catch(err){
            console.log(err)
        }
    }
    const columns = [

        {
            cell: row=><div className="my-3">
                    <Link to={searchAttributeIssue("hasDetails",issue, accesibilityIssues) ? `/main/accesibilitydetails/${issue}/${row.id}` : `/main/accesibilitydetails/viewerpage/?issue=${issue}&url=${row.url}`}>
                    <p className="mb-1 h5 text-primary font-weight-bold">{row.title}</p>
                    {row.url}</Link>
                </div>,
            name: 'Page',
            selector:'url',
            sortable: true,
            grow:2
        },
        {
            cell: row=><a href={row.url} target="_blanck">View page</a>,
            name:'View',
            grow:0.2
        },
        {
            name:'Ocurrences',
            selector:issue,
            sortable:true,
            grow:0.2
        }
    ]

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent placeholder="Filter by Page" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);
    console.log(filteredItems2)
    return(
        <div id="content" className="p-md-5 container-fluid">
            <Helmet>
                <title>Accessibility Issue Details</title>
            </Helmet>
            <div className="d-flex flex-column" id="content-wrapper">
                <h1 className="col-12  text-secondary col-6 rounded">{searchNameIssue(issue, accesibilityIssues)} on <a href={nameWebsite} target="_blanck">{nameWebsite}</a></h1>
                <div className="d-flex flex-wrap mt-4">
                    <div className="skeleton-loader"></div>
                    <div className={`card col-sm-12 col-xl-4 p-4 mx-4 my-2 ${pendingLoading ? "skeleton-loader" : "bg-light"}`}>
                        {
                            !pendingLoading
                            ?
                            <>
                                <div className="card-header card-header-issue"><h4 className="font-weight-bold">Issue description</h4></div>
                                <div className="card-body" dangerouslySetInnerHTML={{__html:searchAttributeIssue("description",issue, accesibilityIssues)}}/>
                                <div className="card-footer"><a href={searchAttributeIssue("documentation",issue, accesibilityIssues)} target="_BLANCK" className="btn btn-primary">Learn more</a></div>
                            </>
                            :
                            ""
                            }
                    </div>
                    <div className={`card col-sm-12 col-xl-6 p-4 mx-4 my-2 ${pendingLoading ? "skeleton-loader" : "bg-light"}`}>
                        {
                            !pendingLoading
                            ?
                            <>
                                <div className="card-header card-header-issue"><h4 className="font-weight-bold">Example solutions to this issue</h4></div>
                                <div className="card-body" dangerouslySetInnerHTML={{__html:searchAttributeIssue("tips",issue,accesibilityIssues)}}/>
                            </>
                            :
                            ""
                            }
                    </div>
                </div>
                <div className="px-4 d-flex flex-column">
                        {
                            !pendingLoading
                            ?
                                <CsvButton data={filteredItems2} name={`${searchNameIssue(issue, accesibilityIssues)} on ${nameWebsite}`}/>
                            :
                            ""
                        }
                        <DataTable
                            columns={columns}
                            data={filteredItems}
                            keyField={'pk'}
                            highlightOnHover
                            pointerOnHover
                            customStyles={customStyles}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            progressPending={pendingLoading}
                            progressComponent={<TableLoader/>}
                        />
                </div>
            </div>
        </div>
    )
}

export default IssueAccesibilityDetails