import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Icon from '../img/icon.png';
import axiosInstance from '../helpers/axiosInstance';
import { customBasicMessageModal } from '../helpers/messages';
import { Helmet } from 'react-helmet';


const Activate = () => {
    const [span, setSpan] = useState('');
    const dataUser = useParams();
    useEffect(() => {
        reset()
    }, [])
    const reset = async() => {
        try{
            await axiosInstance.get(`sign-activate/${dataUser.token}`)
            customBasicMessageModal("success","Account activated","")
            setSpan("activated")
        }catch(err){
            setSpan(err.response.data.detail);
            customBasicMessageModal("error","Error activating account!")
        }
    }
    return (
        <div className="container pt-md-5">
            <Helmet>
                <title>Activate your account</title>
            </Helmet>
            <div className="row justify-content-center pt-md-5">
                <div className="col col-md-8 pt-md-5">
                    <div className="card  my-5 shadow-sm shadow-hover">
                        <div className="col-12 user-img my-4">
                            <img src={Icon} alt="Logo bye bye 404" className="d-block mx-auto "></img>
                        </div>
                        <div className="card-body p-0 ">                         
                            <div class="col-12 text-center" >
                                <h1 class="display-5  text-dark">
                                    { span==='' ? '':'Welcome.'}
                                </h1>
                                <p class=" text-muted ">
                                    { span==='' ? span: 'Congratulations, the account was activated successfully.'}
                                </p>
                                <div class="text-center mb-3">
                                    <Link to="/login" className="btn btn-success">Back to Login</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Activate;