import React, { useEffect, useMemo, useState } from "react"
import { Line } from "react-chartjs-2";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { handleGetData } from "../helpers/requests";
import { customStyles } from "../helpers/tableStyles";
import { accesibilityIssues, countAllIssues, searchLevelIssue, searchNameIssue, searchTechnicalDebt, searchUrlIssue } from "../helpers/utils";
import { SelectWebsite } from "./SelectWebsite";
import TableLoader from "./TableLoader";

import { Helmet } from "react-helmet";
import CsvButton from "./CsvButton";
const AccesibilityDashboard = () => {
    let history = useHistory();
    const[issues,setIssues]=useState([]);
    const[websiteUser,setWebsiteUser] = useState(localStorage.getItem("websiteSelected") ? localStorage.getItem("websiteSelected") : "")
    const[data,setData]=useState([]);
    const[technicalDebt, setTechnicalDebt] = useState()
    const[dataSnap,setDataSnap]=useState([])
    const[dataGraphic,setDataGraphic]=useState()
    const[pendingLoading,setPendingLoading]=useState(true)
    useEffect(() => {
        handleData()
    }, [])

    useEffect(()=>{
        console.log("use effect")
        if(websiteUser!=""&&data.length>0){
            handleIssuesUser(websiteUser)
        }
    },[data.length,websiteUser])

    const handleData = async() => {
        try{
            const {data:dataWebsite} = await handleGetData("websites/?limit=100",history)
            const {data:technicalDebt} = await handleGetData("technicaldebt/")
            const {data:dataSnapAccessibility} = await handleGetData("accessibilitystatistics/",history) 
            setTechnicalDebt(technicalDebt)
            setDataSnap(dataSnapAccessibility.accessibility_statistics)
            setData(dataWebsite.results)
            setPendingLoading(false)
        }catch(err){
            console.log(err)
        }
    }

    const handleSyncData=async()=>{
        await handleData()  
        accesibilityIssues.map(issue=>localStorage.removeItem(issue.id))
        if(websiteUser!=""){
            const websiteSelected = data.filter(website=>website.pk==websiteUser)
            const issuesWebsiteSelected = countAllIssues(websiteSelected[0],accesibilityIssues)
            const dataTable = issuesWebsiteSelected.map(issue=>{
                if(websiteSelected[0][issue]>0){
                    return({
                        id:issue,
                        issue:searchNameIssue(issue, accesibilityIssues),
                        level: searchLevelIssue(issue),
                        ocurrences: websiteSelected[0][issue],
                        pageOcurrences: websiteSelected[0][`page_${issue}_count`],
                        technicalDebtTime: searchTechnicalDebt(technicalDebt,issue)*websiteSelected[0][issue]
                    })
                }
            }).filter(item=>item!==undefined)
            console.log(dataTable)
            dataTable.length>0 ? setIssues(dataTable) : setIssues([])
        }
    }

    const handleIssuesUser = (idWebsite) =>{
        console.log(idWebsite)
        console.log(data)
        const websiteSelected = data.filter(website=>website.pk==idWebsite)
        console.log(websiteSelected)
        const issuesWebsiteSelected = countAllIssues(websiteSelected[0], accesibilityIssues)
        const dataTable = issuesWebsiteSelected.map(issue=>{
            if(websiteSelected[0][issue]>0){
                return({
                    id:issue,
                    issue:searchNameIssue(issue, accesibilityIssues),
                    level: searchLevelIssue(issue),
                    ocurrences: websiteSelected[0][issue],
                    pageOcurrences: websiteSelected[0][`page_${issue}_count`],
                    technicalDebtTime: searchTechnicalDebt(technicalDebt,issue)*websiteSelected[0][issue]
                })
            }
        }).filter(item=>item!==undefined)
        const statisticsWeb = dataSnap.filter(item=>item.website==idWebsite)
        console.log(statisticsWeb)
        const filteredByDate = statisticsWeb.sort((el1,el2)=>{
            const date1 = new Date(el1.date)
            const date2 = new Date(el2.date)
            if(date1<date2){
                return -1
            }else if(date1>date2){
                return 1
            }
        }).filter((el, index, arr) => {
            
            if (index === arr.length - 1 || (new Date(el.date).getDate() + new Date(el.date).getMonth()) !== (new Date(arr[index + 1].date).getDate() + new Date(arr[index + 1].date).getMonth())) {
              return true; 
            }
            return false; 
          }).slice(-10);
        console.log(filteredByDate)
        setDataGraphic(filteredByDate)
        dataTable.length>0 ? setIssues(dataTable) : setIssues([])
    }
    const columns = [

        {
            cell: row=> <Link to={`/main/accesibilityissue/${row.id}/${websiteUser}`}>{row.issue}</Link>,
            name: 'Issue',
            selector:'issue',
            sortable: true,
        },
        {
            name:'Level',
            selector:'level',
            sortable:true,
        },
        {
            name:'Pages with this issue',
            selector:'pageOcurrences',
            sortable:true,
        },
        {
            name: 'Number of occurrences',
            selector: 'ocurrences',
            sortable: true,
        },
        {
            name: 'Technical Debt (minutes)',
            selector: 'technicalDebtTime',
            sortable: true,
        },
    ] 

    const chartData = {  
        labels: dataGraphic?.map(item => new Date(item.date).toDateString()),
        datasets: [
          {
            label: 'Image missing a text alternative',
            data: dataGraphic?.map(item => item.images_without_alt_count),
            borderColor: 'red',
            fill: false
          },
          {
            label: 'Inline frame missing a text alternative',
            data: dataGraphic?.map(item => item.iframes_without_alt_count),
            borderColor: 'blue', 
            fill: false
          },
          {
            label: 'Button missing a text alternative',
            data: dataGraphic?.map(item => item.buttons_without_alt_count),
            borderColor: 'green', 
            fill: false
          },
          {
            label: 'Link missing a text alternative',
            data: dataGraphic?.map(item => item.links_without_alt_count),
            borderColor: 'purple', 
            fill: false
          }
        ]
      };
      console.log(dataGraphic)
    return(
        <>
            <Helmet>
                <title>Accessibility Dashboard</title>
            </Helmet>
            <div id="content" className="p-md-5 container-fluid">
                <div className="d-flex flex-column" id="content-wrapper">
                    <div className="row gradient-card-header  py-2 mx-4 mb-3 d-flex">
                        <SelectWebsite data={data} setWebsiteUser={setWebsiteUser}/>
                    </div>
                    <h1 className="col-12  text-secondary   col-6 rounded">Accesibility Overview</h1>
                    <div className="px-4">
                        <h5 className="font-weight-bold">{issues.length>0 ? `Last accesibility analysis: ${new Date(data[0]?.last_accesibility_analysis).toString()}`:""}</h5>
                            {
                                issues.length>0 || websiteUser!=""
                                ?
                                <>
                                    
                                    <button className="btn btn-success" onClick={handleSyncData}>Synchronize data</button>
                                    {
                                    !pendingLoading
                                    ?
                                    <CsvButton data={issues} name={`Accessibility Overview ${websiteUser}`}/>
                                    :
                                    ""
                                    }
                                    <DataTable
                                        title="Issues in your websites"
                                        columns={columns}
                                        data={issues}
                                        keyField={'pk'}
                                        highlightOnHover
                                        pointerOnHover
                                        customStyles={customStyles}
                                        pagination
                                        persistTableHead
                                        progressPending={pendingLoading}
                                        progressComponent={<TableLoader/>}
                                    />
                                </>
                                :
                                <h4 className="text-center">Select a website to continue</h4>
                            }
                        <h4 className="text-center">{websiteUser!=="" && issues.length===0 ? "Your website currently has no accessibility problems. Congratulations" : ""}</h4>
                    </div>
                    <div className="col-6">
                        {
                            dataGraphic?.length>0
                            ?
                            <>
                                <h4 className="text-center">Progress over time</h4>
                                <Line data={chartData}/>
                            </>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccesibilityDashboard