export const customStyles = {
    headRow: {
        style: {

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '20px',

        },
    },
    rows: {
        style: {
            color: '#202124',
            fontSize: '16px',
        },
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '20px',
        },
    },
    pagination: {
        style: {
            fontSize: '16px',
        },
    },

};