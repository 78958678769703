import React from "react"
const Footer = () => {
    return (
        <footer id="footer" className="page-footer font-small bg-dark text-white">
            <div className="footer-copyright   text-center py-3">© {new Date().getFullYear()} Copyright
                    <a className="text-white" href="https://www.encisosystems.com/">
                    <img className="ml-3" alt="Logo enciso system" href="https://www.encisosystems.com/" src={process.env.PUBLIC_URL + '/Logo-white-30x87.png'} />
                </a>
            </div>
        </footer>

    )
}

export default Footer