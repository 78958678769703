import React from 'react';
import Header from '../../components/landing_components/Header';
import Home from '../../components/landing_components/Home';
import Characteristics from '../../components/landing_components/Characteristics';
import Details from '../../components/landing_components/Detail';
import Regulations from '../../components/landing_components/Regulations';
import Contact from '../../components/landing_components/Contact';
import Footer from '../../components/landing_components/Footer';
import "./App.css"
import { Helmet } from 'react-helmet';

function HomePage() {
  return (
    <div className='landing-page-container'>
      <Helmet>
          <title>Bye Bye 404</title>
      </Helmet>
      <Header />
      <Home />
      <Characteristics />
      <Details />
      <Regulations />
      <Contact />
      <Footer />
    </div>
  );
}

export default HomePage;