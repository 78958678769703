import React from "react";
import "./Regulations.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const Regulations = () => {
  return (
    <>
      <div className="main-section landing-container" id="regulations">
        <h2 className="section-title">Web accessibility laws in Colombia</h2>
        <div className="regulations-main-container">
          <img
            src="/assets/judge.png"
            alt="Web accessibility laws in Colombia"
          />
          <Accordion preExpanded={["a"]}>
            <AccordionItem uuid="a">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <p className="regulation-title">Decree 1151 of 2008:</p>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ul className="regulations-subitem">
                  <li>
                    Establishes the general guidelines for the online government
                    strategy in the Republic of Colombia.
                  </li>
                  <li>
                    Applicable principle: "Equitable and Multichannel Access."
                  </li>
                  <li>
                    Phases of online government: Online Information Phase,
                    Online Interaction Phase, Online Transaction Phase, Online
                    Transformation Phase, and Online Democracy Phase.
                  </li>
                  <li>
                    Institutional responsible: Ministry of Communications
                    through the Connectivity Agenda Program or the corresponding
                    entity.
                  </li>
                </ul>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <p className="regulation-title">Law 1341 of 2009:</p>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ul className="regulations-subitem">
                  <li>
                    Defines the principles and concepts of the information
                    society and the organization of technologies and
                    communications in Colombia.
                  </li>
                  <li>
                    Regulates the general framework, competition regime, and
                    user protection.
                  </li>
                  <li>
                    Addresses aspects such as coverage, service quality,
                    investment promotion, and technological development.
                  </li>
                  <li>
                    Promotes efficient use of networks and radio spectrum.
                  </li>
                </ul>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <p className="regulation-title">Resolution 1519 of 2020:</p>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ul className="regulations-subitem">
                  <li>
                    Aims to establish guidelines for obligated entities to
                    comply with the publication and disclosure of information as
                    mandated by Law 1712 of 2014.
                  </li>
                  <li>
                    Defines criteria for content and information
                    standardization.
                  </li>
                  <li>
                    Addresses topics such as web accessibility, digital
                    security, open data, and electronic form for Requests,
                    Complaints, Claims, Suggestions, and Denunciations (PQRSD).
                  </li>
                </ul>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <div className="regulations-content"></div>
        </div>
      </div>
    </>
  );
};

export default Regulations;
