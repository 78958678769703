import React, { useEffect, useState } from 'react';
import { Link,useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PersonFill, EnvelopeFill, KeyFill } from 'react-bootstrap-icons';
import axiosInstance from '../helpers/axiosInstance';
import { customBasicMessageModal } from '../helpers/messages';
import Icon from '../img/icon2.png';

const Register = () => {
    let history = useHistory();
    const { register, errors, handleSubmit, getValues } = useForm();
    const [span, setSpan] = useState('');
    useEffect(()=>{
        document.title="Sign up for Bye Bye 404"
    },[])
    const onSubmit = async (e) => {
        const {username,email,password,password_repeat} = getValues();
        try {
            await axiosInstance.post("signup/", {
                "username": email,
                "email": email,
                "password": password,  
                "password_confirmation": password_repeat,               
            })
            customBasicMessageModal("info","E-mail sent, check your email to activate the account!","Please check your inbox")
            history.push("/login");
        } catch (error) {
            customBasicMessageModal("error","An error has occurred","Please try again")
            setSpan(error.response.data.non_field_errors);
        }
    }
    return (
        <div className="container ">
            <div className="row justify-content-center ">
                <div className="col-11 col-md-9 card shadow-lg o-hidden border-0 my-3 shadow-sm shadow-hover ">
                    <div className=" card-body ">
                        <div className="row">
                            <div className="col-12 d-none d-lg-flex"></div>
                            <div className="col">
                                <div className="px-3 mt-4">
                                    <h1 className='text-center text-secondary'>Sign up for Bye Bye 404</h1>
                                    <div className="col-12 user-img pt-xl-3 ">
                                        <img src={Icon} alt="Logo bye bye 404" className="d-block mx-auto "></img>
                                    </div>
                                    <div className="text-center">
                                        <h4 className="text-dark mb-4">Create an Account!</h4>
                                        <p className="">Please complete the following form to sign up and make your website a better place!</p>
                                    </div>
                                    <form className="register ml-md-3 ml-lg-5" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group row  pl-md-3 pl-xl-5">
                                            <label htmlFor="InputEmail" className="col-12 text-left">Email Address:</label>
                                            <EnvelopeFill className="mx-3" size={35} />
                                            <input className="form-control form-control-user col-9 "
                                                type="email"
                                                id="InputEmail"
                                                aria-describedby="emailHelp"
                                                placeholder="Email Address"
                                                name="email"
                                                ref={register({
                                                    required: {
                                                        value: true,
                                                        message: 'Email required'
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: 'No more than 100 characters!'
                                                    },
                                                    minLength: {
                                                        value: 8,
                                                        message: 'Minimum 8 characters'
                                                    }
                                                })}
                                                required />
                                            <span className="text-danger text-small d-block mb-2 ml-5 pl-lg-3">
                                                {errors?.email?.message}
                                            </span>
                                        </div>
                                        <div className="form-group row  pl-md-3 pl-xl-5">
                                            <label htmlFor="PasswordInput" className="col-12 text-left">Password:</label>
                                            <KeyFill className="mx-3" size={35} />
                                            <input className="form-control form-control-user  col-9 "
                                                type="password"
                                                id="PasswordInput"
                                                placeholder="Password"
                                                name="password"
                                                ref={register({
                                                    required: {
                                                        value: true,
                                                        message: 'Password required'
                                                    },
                                                    maxLength: {
                                                        value: 64,
                                                        message: 'No more than 64 characters!'
                                                    },
                                                    minLength: {
                                                        value: 8,
                                                        message: 'Minimum 8 characters'
                                                    },
                                                })}
                                                required />
                                            <span className="text-danger text-small d-block mb-2 ml-5 pl-lg-3">
                                                {errors?.password?.message}
                                            </span>
                                        </div>
                                        <div className="form-group row  pl-md-3 pl-xl-5">
                                            <label htmlFor="RepeatPasswordInput" className="col-12 text-left">Repeat Password:</label>
                                            <KeyFill className="mx-3" size={35} />
                                            <input className="form-control form-control-user   col-9 "
                                                type="password"
                                                id="RepeatPasswordInput"
                                                placeholder="Repeat Password"
                                                name="password_repeat"
                                                ref={register({
                                                    required: {
                                                        value: true,
                                                        message: 'Repeat Password required'
                                                    },
                                                    maxLength: {
                                                        value: 64,
                                                        message: 'No more than 64 characters! '
                                                    },
                                                    minLength: {
                                                        value: 8,
                                                        message: 'Minimum 8 characters'
                                                    }
                                                })}
                                                required />
                                            <span className="text-danger text-small d-block mb-2 ml-5 pl-lg-3">
                                                {errors?.password_repeat?.message}
                                            </span>

                                            <p className="col-12 text-left mt-3">By clicking the button, I agree to the <Link to="/privacy-policy" className="" > Privacy Policy</Link> and <Link to="/terms-conditions" >Terms & Conditions.</Link></p>
                                        </div>
                                        <div className="form-group row  pl-md-3 pl-xl-5 text-center">
                                            <button className="btn btn-success btn-block text-white btn-user col-10 ml-3"
                                                type="submit">
                                                Register Account
                                    </button>
                                        </div>
                                        <hr />
                                    </form>
                                    <div className="text-center ">
                                        <Link className=" text-primary" to="/password-recovery">Forgot Password?</Link>
                                    </div>
                                    <div className="text-center ">
                                        <Link className=" text-primary" to="/login">Already have an account ? Login!</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;