import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useHistory } from 'react-router-dom';
import FilterComponent from './FilterComponent';
import { customStyles } from '../helpers/tableStyles';
import { axiosUserLog } from '../helpers/axiosInstance';
import { handleGetData } from '../helpers/requests';
import { SelectWebsite } from './SelectWebsite';
import TableLoader from './TableLoader';
import { Helmet } from 'react-helmet';
import CsvButton from './CsvButton';

const MisspellingsDashboard = () => {

    let history = useHistory();

    const columns = [
        {
            cell: row=><div className="my-3">
                    <Link to={`/main/misspellingsdetails/${row.id}`}>
                    <p className="mb-1 h5 text-primary font-weight-bold">{row.title}</p>
                    {row.url}</Link>
                </div>,
            name: 'Page',
            selector: 'url',
            sortable: true,
        },
        {
            cell: row => <p className="mt-3">{row?.language ?? ''}</p>,
            name: 'Language',
            sortable: true,
            grow: 0.3
        },
        {
            cell: row => <p className="mt-3">{row.misspellings}</p>,
            name: 'Misspellings',
            sortable: true,
            grow: 0.3
        }
    ];

    const [selectedWebsites, setSelectedWebsites] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const[websiteUser,setWebsiteUser] = useState(localStorage.getItem("websiteSelected") ? localStorage.getItem("websiteSelected") : "")
    const [data, setData] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = (data.length>0) ? data.filter(item => item.url && item.url.toLowerCase().includes(filterText.toLowerCase())) : [];
    const filteredItems2 = (data.length>0) ? data.map(item => {
        return (
        {
            url:item.url,
            website:item.website?.start_url,
            title:item?.title,
            language: item?.language,
            misspellings: item?.misspellings
        })
        
    }) : [];
    const[pendingLoading,setPendingLoading]=useState(true)
    useEffect(() => {
        setPendingLoading(true)
        handleData()
    }, [selectedWebsites.length,websiteUser])

    const handleWebsite = async() => {
        try{
            const {data:dataWebsites} = await axiosUserLog().get("websites/?website=pk,start_url&limit=100");
            setSelectedWebsites(dataWebsites.results)
        }catch(err){
            console.log(err)
        }
    };

    const handleData = async() => {  
        try{
            if(websiteUser!=""){
                const {data} = await handleGetData(`pages/?idWebsite=${websiteUser}&page=id,url,home_page,website,title,misspellings,language&website=pk,start_url`, history);
                console.log(data)
                setData(data.filter(p=>p.website.pk==websiteUser && p?.misspellings>0))
                setPendingLoading(false)
            }
            await handleWebsite()
        }catch(err){
            console.log(err)
        }
    }

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
        return <FilterComponent placeholder="Filter by page" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    console.log(filteredItems)
    console.log(filteredItems2)
    return (
        <>
            <Helmet>
                <title>Misspellings Dashboard</title>
            </Helmet>
            <div id="content" className="p-md-5 container-fluid ">
                <div className="d-flex flex-column" id="content-wrapper">
                    <div className="row gradient-card-header  py-2 mx-4 mb-3 d-flex">
                        <SelectWebsite data={selectedWebsites} setWebsiteUser={setWebsiteUser}/>
                    </div>
                    <div className="card card-cascade narrower  border-0 ">
                        <div className="row gradient-card-header  py-2 mx-4 mb-3 d-flex d-block">
                            <h1 className="col-12  text-secondary   col-6 rounded">Misspellings Dashboard</h1>
                            <h6 className="col-12  text-secondary  bg-light-blue p-3 col-6 rounded">Your Pages with misspellings.</h6>
                        </div>
                        <div className="row  px-4">

                        </div>
                        <div className="px-4">
                            {
                                websiteUser!=""
                                ?
                                <>
                                    {
                                    !pendingLoading
                                    ?
                                    <CsvButton data={filteredItems2} name={`Misspellings Overview ${websiteUser}`}/>
                                    :
                                    ""
                                    }
                                    <DataTable
                                        columns={columns}
                                        data={filteredItems}
                                        keyField={'pk'}
                                        highlightOnHover
                                        pointerOnHover
                                        customStyles={customStyles}
                                        pagination
                                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                        persistTableHead
                                        clearSelectedRows={toggleCleared}
                                        progressPending={pendingLoading}
                                        progressComponent={<TableLoader/>}
                                    />
                                </>
                                :
                                <h4 className="text-center">Select a website to continue</h4>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>

    );

}
export default MisspellingsDashboard;