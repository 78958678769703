import ReactCardFlip from "react-card-flip";
import React from "react";
const DetailsCard = ({
  handleFlip,
  isFlip,
  imgCard,
  altImg,
  titleCard,
  textCard,
}) => {
  return (
    <>
      <ReactCardFlip isFlipped={isFlip} flipDirection="horizontal">
        <div
          className="feature-card front-card"
          onClick={() => handleFlip(!isFlip)}
        >
          <img src={imgCard} alt={altImg} />
          <p className="card-details-title">{titleCard}</p>
        </div>
        <div
          className="feature-card back-card"
          onClick={() => handleFlip(!isFlip)}
        >
          <p>{textCard}</p>
        </div>
      </ReactCardFlip>
    </>
  );
};
export default DetailsCard;
