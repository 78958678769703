import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router";
import { handleGetData } from "../helpers/requests";
import { customStyles } from "../helpers/tableStyles";
import { accesibilityIssues, changeDate, createAbsolutePath, createVisualTag, getDomain, searchAttributeIssue } from "../helpers/utils";
import TableLoader from "./TableLoader";
import { Helmet } from "react-helmet";
import "./AccesibilityPageDetails.css"
import CsvButton from "./CsvButton";
const AccesibilityPageDetails=()=>{
    const {issue,idPage} = useParams()
    const history = useHistory()
    const [dataPage, setDataPage] = useState([])
    const [pendingLoading,setPendingLoading]=useState(true)
    const filteredItems2 = (dataPage.length>0) ? dataPage.map(item => {
        return {
            created: item.created,
            page: item.page.url,
            srcAbsolutePath:createAbsolutePath(getDomain(dataPage[0]?.page.url), item.srcAttribute),
        }
        }
     ) : [];
    useEffect(()=>{
        handleData()
    },[])

    const handleData=async()=>{
        try{
            const {data} = await handleGetData(`${searchAttributeIssue("url",issue, accesibilityIssues)}/?idPage=${idPage}&page=title,url`,history)
            console.log(data)         
            setDataPage(data)

            setPendingLoading(false)
        }catch(err){
            console.log(err)
        }
    }

    const columns = [
        {
            cell: row => <p className="mt-3">{changeDate(row.created)}</p>,
            name:'Created',
            sortable:true,
            grow:0.5
        }
        ,
        {
            cell: row => <p className="mt-3">{row.srcAttribute}</p>,
            name:'Source attribute',
            
        },
        {
            name:'Count',
            selector:`${searchAttributeIssue("countSelector",issue, accesibilityIssues)}`,
            sortable:true,
            grow:0.2,
        },
        {
            cell: row=><div className="preview-image" dangerouslySetInnerHTML={{__html: `<a target='_blank' href=${ createAbsolutePath(getDomain(dataPage[0]?.page.url), row[searchAttributeIssue("src",issue, accesibilityIssues)])}>View</a>`}}></div>,
            name:'View',
            grow:0.2,            
        },
        {
            cell: row=><div className="preview-image" dangerouslySetInnerHTML={{__html:createVisualTag(searchAttributeIssue("htmlTag",issue, accesibilityIssues), createAbsolutePath(getDomain(dataPage[0]?.page.url), row[searchAttributeIssue("src",issue, accesibilityIssues)]) )}}></div>,
            name:'Preview',
            grow: 3
        }
    ]

    console.log(dataPage)

    return(
        <div id="content" className="p-md-5 container-fluid">
            <Helmet>
                <title>Accessibility Page Details</title>
            </Helmet>
            <div className="d-flex flex-column" id="content-wrapper">
                <h1 className="col-12  text-secondary col-6 rounded">Details page {!pendingLoading ? <a href={dataPage[0]?.page.url} target="blanck">{dataPage[0]?.page.title}</a> : ""}</h1>
                <div className="px-4 d-flex flex-column">
                        {
                            !pendingLoading
                            ?
                            <CsvButton data={filteredItems2} name={`Details page ${dataPage[0]?.page.title}`}/>
                            :
                            ""
                        }
                        <DataTable
                            columns={columns}
                            data={dataPage}
                            keyField={'pk'}
                            pointerOnHover
                            customStyles={customStyles}
                            pagination
                            subHeader
                            persistTableHead
                            progressPending={pendingLoading}
                            progressComponent={<TableLoader/>}
                        />
                </div>
            </div>
        </div>
    )
}

export default AccesibilityPageDetails