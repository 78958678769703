import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { axiosUserLog } from "../helpers/axiosInstance";
import { PayPalButton } from 'react-paypal-button-v2';
import { customBasicMessageToast } from "../helpers/messages";
import { useHistory } from "react-router-dom";
import { Cart, Reception4 } from "react-bootstrap-icons";

const SummaryPurchase=()=>{
    const history = useHistory()
    const {id} = useParams()
    const[dataPayment,setDataPayment]= useState()
    console.log(id)
    useEffect(()=>{
        getInfoPayment()
    },[])
    const getInfoPayment=async()=>{
        const {data:dataPlan} = await axiosUserLog().get(`plans/${id}`)
        setDataPayment(dataPlan)
    }
    const handleSuccessPayment=async(details,data)=>{
        const {data:dataPayment} = await axiosUserLog().post("payment/process-payment/",{
            plan_id:id,
            account_id:localStorage.getItem("userId"),
            payment_id:details.id,
            order_id:data.orderID,
            payer_id:data.payerID
        })
        customBasicMessageToast(1500,"success","Successful purchase")
        history.push("/main/upgrade")
    }
    return(
        <div id="content" className="p-md-5 container-fluid">
            <h1>Summary purchase</h1>
            <div className="d-flex">
                {
                    dataPayment
                    ?
                    <>
                    <div className=" card  mb-4 box-shadow col-11 col-sm-3 mt-5 border m-3 pb-4 h-75">
                        <div className="card-header bg-white text-center">
                            <Reception4 className="mr-2 my-3" color="gray" size={100} />
                            <h2 className="my-0 font-weight-normal text-muted">Your custom plan</h2>
                            <h1 className="card-title pricing-card-title text-success">${parseFloat(dataPayment.cost/12).toFixed(2)} <small
                                className="text-muted">/ mo</small></h1>
                            <h5 className="card-title pricing-card-title">1$ per page per year</h5>
                        </div>
                        <div className="card-body text-center pt-4 d-flex justify-content-center align-items-center">
                            <ul className="list-unstyled mt-3 mb-4 text-muted">
                                <li><h4 className="card-title pricing-card-title text-success"><small
                                    className="text-muted">Total pages: </small>{dataPayment.max_pages}</h4></li>
                                <li><h4 className="card-title pricing-card-title text-success"><small
                                    className="text-muted">Cost per year: </small><br></br>${dataPayment.cost} USD</h4></li>
                            </ul>
                        </div>
                    </div>
                    <div className="mb-4 box-shadow col-11 col-sm-3 mt-5 m-3 pb-4">
                    <PayPalButton
                        amount={`${dataPayment.cost}`}
                        currency="USD"
                        onSuccess={handleSuccessPayment}
                        onError={()=>customBasicMessageToast(1500,"error","An error occurred during the transaction")}
                        onCancel={()=>customBasicMessageToast(1500,"info","The purchase has been cancelled")}
                        options={
                            {
                                clientId:"Ac4zQoIPDQvtFtRbm6ooePxMN0qdpNrW4XIIGpt_ncXRtlH5D-G7V_RQGfL5YwvWTz-WWJ36z_OSRdqs"
                            }
                        }
                    />
                    </div>
                    </>
                    :
                    <></>
                }
                
            </div>
        </div>    
    )
}

export default SummaryPurchase;

