import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router";
import { handleGetData } from "../helpers/requests";
import { customStyles } from "../helpers/tableStyles";
import { accesibilityIssues, changeDate, createVisualTag, searchAttributeIssue } from "../helpers/utils";
import TableLoader from "./TableLoader";
import { Helmet } from "react-helmet";
import CsvButton from "./CsvButton";
const MisspellingsPageDetails=()=>{
    const {idPage} = useParams()
    const history = useHistory()
    const [dataPage, setDataPage] = useState([])
    const [pendingLoading,setPendingLoading]=useState(true)
    const[misspellings,setMisspellings]=useState({})

    console.log(idPage)
    useEffect(()=>{
        handleData()  
        handleGetMisspellings()  
    },[])
    
    useEffect(()=>{
        handleGetMisspellings()  
    },[dataPage])

    const handleData=async()=>{
        try{
            const {data} = await handleGetData(`misspellings/?idPage=${idPage}`,history)
            console.log(data)         
            setDataPage(data)
            setPendingLoading(false)
        }catch(err){
            console.log(err)
        }
    }

    const handleGetMisspellings=()=>{
        console.log(dataPage)
        const words = dataPage[0]?.misspellings?.split(" ")
        const wordCount = {};
        if(!words){
            return
        }
        words.forEach(word => {
            wordCount[word] = (wordCount[word] || 0) + 1;
        });
        setMisspellings(wordCount)
        console.log(wordCount);
        console.log(words)
    }


    const formatMisspellingsData = (misspellings) => {
        return Object.entries(misspellings).map(([word, count], index) => {
            return {
                word: word,
                count: count
            };
        });
    };
    
    const columns = [
        {
            cell: row => <p className="mt-3">{row.word}</p>,
            name:'Word',
            
        },
        {
            name:'Count',
            selector:'count',
            sortable:true,
            grow:0.2,
        }
    ]



    return(
        <div id="content" className="p-md-5 container-fluid">
            <Helmet>
                <title>Misspellings Page Details</title>
            </Helmet>
            <div className="d-flex flex-column" id="content-wrapper">
                <h1 className="col-12  text-secondary col-6 rounded">Details page {!pendingLoading ? <a href={dataPage[0]?.page.url} target="blanck">{dataPage[0]?.page.title ? dataPage[0]?.page.title : dataPage[0]?.page.url} </a> : ""}</h1>
                {
                    misspellings
                    ?
                        <div className="px-4 d-flex flex-column">
                        {
                            !pendingLoading
                            ?
                            <CsvButton data={formatMisspellingsData(misspellings)} name={`Details page ${dataPage[0]?.page.title}`}/>
                            :
                            ""
                        }
                                <DataTable
                                    columns={columns}
                                    data={formatMisspellingsData(misspellings)}
                                    keyField={'pk'}
                                    pointerOnHover
                                    customStyles={customStyles}
                                    pagination
                                    subHeader
                                    persistTableHead
                                    progressPending={pendingLoading}
                                    progressComponent={<TableLoader/>}
                                />
                        </div>
                    :
                    ''
                }
            </div>
        </div>
    )
}

export default MisspellingsPageDetails