import React, { Fragment} from "react"
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom'

const PageNotFound = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Page Not Found</title>
            </Helmet>
            <div id="not_fount" className="p-md-5 container-fluid bg-white border">
                <div className="d-flex flex-column h-100" id="content-wrapper">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <img alt="not found" src={process.env.PUBLIC_URL + '/icon.png'} />
                            <span className="display-1 d-block">404</span>
                            <div className="mb-4 lead">The page you are looking for was not found.</div>
                            <Link to="/" className="btn btn-success">Back to Home</Link>
                        </div>
                    </div>

                </div>
            </div>

        </Fragment>
    )
}

export default PageNotFound;