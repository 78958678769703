import React, { Fragment, useState } from "react"
import { useForm } from 'react-hook-form';
import { KeyFill, EnvelopeFill } from "react-bootstrap-icons";
import { Link, useHistory } from 'react-router-dom';
import { axiosUserLog } from "../helpers/axiosInstance";
import { customBasicMessageModal, customConfirmMessage } from "../helpers/messages";
import { Helmet } from "react-helmet";

const Settings = () => {
    const [emailUser,setEmailUser] = useState("")
    const { register, errors, handleSubmit, getValues} = useForm();
    let history = useHistory();
    const handleSubmitPassword = async e => {
        const {old_password,password,password_repeat} = getValues()
        const data = {
            "old_password": old_password,
            "new_password": password,
            "password_confirm": password_repeat,
        }
        customConfirmMessage("question","Are you sure you want to change the password?","",async()=>{
            try{
                await axiosUserLog().patch('change-password/', data);
                localStorage.clear();
                customBasicMessageModal("success","Password changed","")
                history.push("/login");
            }catch(err){
                customBasicMessageModal("error","Password change failed, try again!","")
            }
        })
    }
    const handleSubmitEmail = async e => {
        e.preventDefault()
        const data = {
            "email": emailUser,
            "username":emailUser
        }
        customConfirmMessage("question","Are you sure you want to change the email?","",async()=>{
            try{
                await axiosUserLog().patch('account/', data)
                customBasicMessageModal("success","Email successfully changed","")
            }catch(err){
                console.log(err)
                customBasicMessageModal("error","This email is already registered","")
            }
        })
    }

    const handleDeleteAccount = async e => {
        customConfirmMessage("warning","Are you sure you want to delete the account?","",async()=>{
            try{
                await axiosUserLog().delete('account/')
                customBasicMessageModal("success","Account deleted","")
                localStorage.clear();
                history.push("/login");
            }catch(err){
                customBasicMessageModal("error","Account deletion failed, try again!","")
            }
        })
    }

    return (
        <Fragment>
            <Helmet>
                <title>Settings</title>
            </Helmet>
            <div id="wrapper " className=" mt-1 p-md-5 bg-light-blue">
                <div className="d-flex flex-column " id="content-wrapper">
                    <div id="content">
                        <div className="row  justify-content-center">
                            <div className="card col-10 col-sm-10 col-md-8 col-lg-6 shadow">
                                <div className="card-header border-0 bg-white mx-3">
                                    <h3 className="card-title text-dark text-center">New Password</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <div className="">
                                                <form className="user  ml-5" onSubmit={handleSubmit(handleSubmitPassword)}>
                                                    <div className="form-group row  ">
                                                        <KeyFill className="mx-3" size={35} />
                                                        <input className="form-control form-control-user  col-7 col-sm-9 "
                                                            type="password"
                                                            id="OldPasswordInput"
                                                            placeholder="Old password"
                                                            name="old_password"
                                                            ref={register({
                                                                required: {
                                                                    value: true,
                                                                    message: 'Password required'
                                                                },
                                                                maxLength: {
                                                                    value: 65,
                                                                    message: 'No more than 65 characters!'
                                                                },
                                                                minLength: {
                                                                    value: 8,
                                                                    message: 'Minimum 8 characters'
                                                                },

                                                            })}
                                                            required
                                                        />
                                                        <span className="text-danger text-small d-block mb-2 ml-5 pl-lg-3">
                                                            {errors?.password?.message}
                                                        </span>
                                                    </div>
                                                    <div className="form-group row ">
                                                        <KeyFill className="mx-3" size={35} />

                                                        <input className="form-control form-control-user  col-7 col-sm-9 "
                                                            type="password"
                                                            id="NewPasswordInput"
                                                            placeholder="New password"
                                                            name="password"
                                                            ref={register({
                                                                required: {
                                                                    value: true,
                                                                    message: 'Password required'
                                                                },
                                                                maxLength: {
                                                                    value: 65,
                                                                    message: 'No more than 65 characters!'
                                                                },
                                                                minLength: {
                                                                    value: 8,
                                                                    message: 'Minimum 8 characters'
                                                                },

                                                            })}
                                                            required
                                                        />
                                                        <span className="text-danger text-small d-block mb-2 ml-5 pl-lg-3">
                                                            {errors?.password?.message}
                                                        </span>
                                                    </div>
                                                    <div className="form-group row ">
                                                        <KeyFill className="mx-3" size={35} />

                                                        <input className="form-control form-control-user   col-7 col-sm-9 "
                                                            type="password"
                                                            id="NewRepeatPasswordInput"
                                                            placeholder="New password confimation"
                                                            name="password_repeat"
                                                            ref={register({
                                                                required: {
                                                                    value: true,
                                                                    message: 'Password required'
                                                                },
                                                                maxLength: {
                                                                    value: 65,
                                                                    message: 'No more than 65 characters!'
                                                                },
                                                                minLength: {
                                                                    value: 8,
                                                                    message: 'Minimum 8 characters'
                                                                },

                                                            })}
                                                            required
                                                        />
                                                        <span className="text-danger text-small d-block mb-2 ml-5 pl-lg-3">
                                                            {errors?.password?.message}
                                                        </span>
                                                    </div>
                                                    <div className="form-group row  text-center">
                                                        <button className="btn btn-success btn-block text-white btn-user col-10 ml-3 "
                                                            type="submit">
                                                            Change Password
                                                                </button>
                                                    </div>
                                                </form >
                                                <ul className="mt-3 ml-5">
                                                    <li className="text-muted">Your password can't be too similar to your other personal information.</li >
                                                    <li className="text-muted">Your password must contain at least 8 characters.</li >
                                                    <li className="text-muted">Your password can't be a commonly used password.</li >
                                                    <li className="text-muted">Your password can't be entirely numeric.</li >
                                                </ul>

                                                <div className="border-top border-bottom bg-white ">
                                                    <h3 className="card-title text-dark text-center mt-3">Change account information</h3>

                                                    <div className="card-body ml-5 ">
                                                        <form className="user" onSubmit={handleSubmitEmail}>

                                                            <div className="form-group row  ">
                                                                <EnvelopeFill className="mx-3" size={35} />
                                                                <input className="form-control form-control-user col-7 col-sm-9 "
                                                                    type="email"
                                                                    id="ChangeInputEmail"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Change email Address"
                                                                    name="Change email"
                                                                    onChange={(e)=>setEmailUser(e.target.value)}
                                                                    required />

                                                            </div>
                                                            <div className="form-group row  text-center">
                                                                <button className="btn btn-success btn-block text-white btn-user col-10 ml-3 "
                                                                    type="submit">
                                                                    Apply Changes
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="bg-white">
                                                    <h3 className="card-title text-dark text-center mt-3">Delete account</h3>
                                                    <div className="card-body ml-5">
                                                        <div className="form-group row ">
                                                            <button onClick={handleDeleteAccount} className="btn btn-danger btn-block text-white btn-user col-10 ml-3"
                                                                type="submit">
                                                                Delete Your Account
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </Fragment>
    )
}

export default Settings;