import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer id="about-us">
      <div className="main-section landing-container footer-container">
        <div className="footer-contact">
          <p className="footer-title">Social Media</p>
          <ul className="social-container">
            <li>
              <a href="https://twitter.com/byebye404" target="_blank">
                <i class="bi bi-twitter"></i>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/byebye404" target="_blank">
                <i class="bi bi-facebook"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/byebye404/about/"
                target="_blank"
              >
                <i class="bi bi-linkedin"></i>
              </a>
            </li>
          </ul>
          <p className="footer-title">Privacy Policy</p>
          <ul className="footer-contact-container">
            <li className="footer-link"><Link to={"/privacy-policy"}>Our Privacy Policy</Link></li>
          </ul>
          <p className="footer-title">Terms & Conditions</p>
          <ul className="footer-contact-container">
            <li className="footer-link"><Link to={"/terms-conditions"}>Our Terms & Conditions</Link></li>
          </ul>
        </div>
        <div className="footer-contact">
          <p className="footer-title">Contact</p>
          <ul className="footer-contact-container">
            <li className="footer-link">Address: Calle 35B # 19D - 40</li>
            <li className="footer-link">Villavicencio, Colombia</li>
            <li className="footer-link">Phone: +57 (608) 666 1133</li>
            <li className="footer-link">Cell: +57 320 880 8381</li>
            <li className="footer-link">
              Email:
              <a href="mailto:info@encisosystems.com">
                {" "}
                info@encisosystems.com
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="copyright-section">
        <p>© {new Date().getFullYear().toString()} Copyright</p>
        <a href="https://www.encisosystems.com/" target="_blank">
          <img src="/assets/esystems_logo.png" alt="Enciso Systems Logo" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
