import React from "react"

import CsvDownloadButton from 'react-json-to-csv'

export default function CsvButton ({data, name}) {
    return(
        <CsvDownloadButton
        data={data}
        filename={name}
        style={{ //pass other props, like styles
          width:"max-content",
          alignSelf:"end",
          background:"#007bff",
          backgroundColor:"#007bff",
          borderRadius:"4px",
          border:"1px solid #007bff",
          display:"inline-block",
          cursor:"pointer","color":"#ffffff",
          fontSize:"15px",
          fontWeight:"bold",
          padding:"6px 24px",
          textDecoration:"none",
          margin:"16px 8px 0 8px"
          }}
      >
        Download CSV
      </CsvDownloadButton>
    )
}