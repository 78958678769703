import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { accesibilityIssues, searchNameIssue } from '../helpers/utils';
import TableLoader from './TableLoader';

function WebPageViewerErrors() {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const issue = queryParams.get('issue');
    const url = queryParams.get('url');
    const [content, setContent] = useState(null);

    useEffect(() => {
        fetch(url)
            .then((response) => response.text())
            .then((html) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');
                let tagForView;
                if(issue == "links_without_alt"){

                    tagForView = Array.from(doc.querySelectorAll(`a`));
                    
                    tagForView.forEach((tag) => {
                        if(tag.childNodes[0].textContent.trim()==""){
                            tag.style.display = "inline-block";
                            if(tag.getAttribute("aria-label")==null){
                                
                                tag.style.border = '4px solid red';
                            }else if(tag.getAttribute("aria-label")?.trim()==""){
                                tag.style.border = '4px solid red';
                            }
                        }
                    });

                }else if(issue == "buttons_without_alt"){
                    tagForView = Array.from(doc.querySelectorAll(`
                    *[role="button"]:not([aria-label]),
                    *[role="button"][aria-label=""],
                    button:not([aria-label]),
                    button[aria-label=""],
                    input:not([value]),
                    input[value=""]
                    `));
                    tagForView.forEach((tag) => {
                        tag.style.border = '4px solid red';
                    });
                }

                // Agrega un borde rojo a los botones sin aria-label

                setContent(doc.documentElement.innerHTML);
            });
    }, [url]);

    return (
        <div id="content" className="p-md-5 container-fluid">
            <h1>{searchNameIssue(issue, accesibilityIssues)} on <a href={url} target="_blanck">{url}</a></h1>
            {
                content
                ?
                <div className='d-flex justify-content-center'>
                    <div className='w-75 border border-primary p-4' dangerouslySetInnerHTML={{ __html: content }} />

                </div>
                :
                <div className='w-full d-flex justify-content-center'>
                    <TableLoader/>
                </div>
            }
        </div>
    );
}

export default WebPageViewerErrors;
