import React, { useEffect, useRef, useState } from 'react';
import { Cart, Reception3, Reception4, Reception1, Pencil } from 'react-bootstrap-icons';
import ReactModal from 'react-modal';
import { ModalProvider, useModal } from 'react-modal-hook';
import { axiosUserLog } from '../helpers/axiosInstance';
import CardPlan from './CardPlan';
import { customBasicMessageModal, customBasicMessageToast } from '../helpers/messages';

const PlanCreationForm=({hideModal, savePlan, setSavePlan})=>{
    const [totalPages, setTotalPages] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
        await axiosUserLog().post("plans/", {
            max_pages: parseInt(totalPages),
        });
        setSavePlan(!savePlan)
        customBasicMessageToast(1500,"success","Plan successfully created")
        } catch (err) {
            console.log(err);
            customBasicMessageModal("error","Error creating new plan","Try again")
        }
        hideModal();
    };
    return(
        <form className="row" onSubmit={handleSubmit}>
            <div className="col-12 form-group">
                <button type="button" className="close" aria-label="Close" onClick={hideModal}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <h3 className="text-center text-secondary" >Customize your plan</h3>
            </div>
            <div className="col-12 form-group pb-3" >
                <label >Total pages:</label>
                <input type="number" className="form-control" id="inputTotalPagesWebsite" placeholder="Enter the total number of pages per website to be analyzed " name="inputTotalPagesWebsite" min="0" required onChange={(e)=>setTotalPages(e.target.value)}/>            
            </div>
            <div className="col-6 small">
                <button type="button" className="btn btn-secondary col-12 " onClick={hideModal}>Close</button>
            </div>
            <div className="col-6 small">
                <button type="submit" className="btn btn-success col-12 ">Save</button>
            </div>
        </form>
    )
}


const TableUpgrade = () => {
    const[savePlan,setSavePlan] = useState(false)
    const [showModal, hideModal] = useModal(() => {
        return(
            <ReactModal isOpen className="p-3 mx-auto bg-white border col-10 col-md-5 mt-lg-5" ariaHideApp={false}>
                <PlanCreationForm hideModal={hideModal} setSavePlan={setSavePlan} savePlan={savePlan}/>
            </ReactModal>
        )
        }
    );
    const[userPlan,setUserPlan]=useState()
    useEffect(()=>{
        getInfoAccount()
    },[savePlan])
    const getInfoAccount=async()=>{
        const {data} = await axiosUserLog().get("account/")
        setUserPlan(data)
    }
    return (
        <div id="wrapper " className="p-md-2 bg-light-blue">
            <div className="d-flex flex-column " id="content-wrapper">
                <div id="content">
                    <div className="row justify-content-center text-center">

                        <div className=" card  mb-4 box-shadow col-11 col-sm-3 mt-5 border m-3">
                            <div className="card-header bg-white text-center">
                            <Reception1 className="mr-2 my-3" color="gray" size={100} />
                                <h2 className="my-0 font-weight-normal text-muted">Free</h2>
                                <h1 className="card-title pricing-card-title text-success">$0 <small
                                    className="text-muted">/ mo</small></h1>
                            </div>
                            <div className="card-body d-flex flex-column justify-content-between">

                                <ul className="list-unstyled mt-3 mb-4 text-muted">
                                    <li><h4 className="card-title pricing-card-title text-success"><small
                                        className="text-muted">Max pages: </small>10</h4></li>
                                    <li><h4 className="card-title pricing-card-title text-success"><small
                                        className="text-muted">Cost per year: </small><br></br>$0.0 USD</h4></li>
                                </ul>
                                <button type="button"
                                    className="btn btn-lg btn-block btn-outline-success" disabled>
                                    <Cart className="mr-1 my-1 " size={30} /> Sign up for free
                                </button>
                            </div>
                        </div>
                        {
                            userPlan?.plan?.cost 
                            ?
                            <CardPlan id={userPlan.plan.id} state={userPlan.plan.enabled} title="Your custom plan" priceMonth={parseInt(userPlan.plan.cost)/12} totalPages={userPlan.plan.max_pages} priceYear={userPlan.plan.cost} savePlan={savePlan} setSavePlan={setSavePlan}/>
                            :
                            <div className=" card  mb-4 box-shadow col-11 col-sm-3 mt-5 border m-3">
                                <div className="card-header bg-white text-center h-50">
                                <Reception4 className="mr-2 my-3" color="gray" size={100} />
                                    <h2 className="my-0 font-weight-normal text-muted">Customized</h2>
                                </div>
                                <div className="card-body d-flex flex-column justify-content-between">
                                    <h4 className="my-0 font-weight-normal text-muted">Customize your plan according to your needs</h4>
                                    <h4 className="my-0 font-weight-normal text-muted"><p className='text-success d-inline'>1$</p> per page per year</h4>
                                    <button type="button align-self-end" onClick={showModal}
                                        className="btn btn-lg btn-block btn-outline-success">
                                        <Cart className="mr-1 my-1 " size={30} /> Create new plan
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TableUpgrade;
