import React, { useEffect, useMemo, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import {  Check2, EyeSlashFill } from 'react-bootstrap-icons';
import { useModal, ModalProvider } from "react-modal-hook";
import ReactModal from 'react-modal';
import { Pencil, Plus } from 'react-bootstrap-icons';
import FilterComponent from './FilterComponent';
import { customStyles } from '../helpers/tableStyles';
import { changeDate, searchTechnicalDebt } from '../helpers/utils';
import { handleGetData } from '../helpers/requests';
import { axiosUserLog } from '../helpers/axiosInstance';
import { customBasicMessageModal, customBasicMessageToast, customConfirmMessage } from '../helpers/messages';
import { SelectWebsite } from './SelectWebsite';
import TableLoader from './TableLoader';
import { Helmet } from 'react-helmet';
import CsvButton from './CsvButton';

const Create = (props) => {
    const [hintProxy, setHintProxy] = useState(null);
    useEffect(() => {
        if (props.row.hint_proxy) {
            axiosUserLog().get(`hints/${props.row.hint_proxy}/`).then(response => {
                console.log(response)
                setHintProxy(response.data.fixed_url);
            });
        } else {
            //setHintProxy('No hint');
        }
    }, [])
    const websiteRef = useRef(null);
    const [showModal, hideModal] = useModal(() => (  
        <ReactModal isOpen className="p-3 mx-auto bg-white  border col-10 col-md-5 mt-lg-5" ariaHideApp={false}>
            <form className="row" onSubmit={(e)=>{
                e.preventDefault()
                hideModal();
                props.create(props.row, websiteRef.current.value);
            }} >
                <div className="col-12 form-group">

                    <button type="button" className="close" aria-label="Close" onClick={hideModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h3 className="text-center text-secondary" >{(props.row.hint_proxy) ? 'Edit hint' : 'Add hint'}</h3>
                </div>
                <div className="col-12 form-group " >
                    <label >Hint:</label>
                    <input type="url" ref={websiteRef} className="form-control" id="inputWebsite" placeholder="Enter Hint" name="website"  defaultValue={(hintProxy==null)? '':hintProxy} required />

                </div>

                <div className="col-6 small">
                    <button type="button" className="btn btn-secondary col-12 " onClick={hideModal}>Close</button>
                </div>
                <div className="col-6 small">
                    <input type="submit" className="btn btn-success col-12 " value="Save"/>
                </div>
            </form>
        </ReactModal>
    ), [hintProxy]);

    return (
        <div className="input-group">
            <textarea aria-label="With textarea" id="hintArea" rows="1" className="form-control" value={hintProxy ? hintProxy:'No hint'} disabled></textarea >
            <div className="input-group-append">
                <button onClick={showModal} className={props.className} >{(props.row.hint_proxy) ? <Pencil className="" size={20}/> : <Plus className="" size={20} />}</button>
                <button className="btn btn-outline-danger" type="button" onClick={() => {props.delete(props.row.hint_proxy)}}>X</button>
            </div>
        </div>   
    
    );
};
const TableBroken = () => {
    let history = useHistory();
    const columns = [
        {
            cell: row => <p className="mt-3">{changeDate(row.created)}</p>,
            name: 'Detected',
            selector: 'created',
            
            grow: 0.3,
        },

        {
            cell: row => <a href={row.page.url} className="mt-3">{row.page.url}</a>,
            name: 'Page',
            
            grow: 0.3,
        },
        {
            cell: row => <a href={row.url} className="mt-3">{row.url}</a>,
            name: 'Broken Link',
            selector: 'url',
            
            grow: 0.5,
        },
        {
            cell: row =>
            <div className="input-group-append">
                <a href={'https://web.archive.org/web/*/'+row.url} title="Open with Web Archive" className="btn btn-outline-secondary m-1" type="button">A</a>
                <a href={'https://www.google.com/search?q='+row.url} title="Search with Google" className="btn btn-outline-secondary m-1" type="button">G</a>
           </div>
            ,
            name: 'Search Engines',
            
            grow: 0.2,
        },
        {
            cell: (row) => 
            <ModalProvider>
                <Create                 
                icon="action icon ion-md-create" 
                className="btn btn-outline-secondary " 
                row={row} 
                create={row.hint_proxy ? handleUpdate:handleCreate}
                delete={handleDeleteOne} />
            </ModalProvider>,
            name: 'Hint',
            selector: 'hint_proxy',
            grow: 1,
        },
        {
            name: 'Actions',
            cell: row => <div><button type="button" title="Fixed" className="p-1 button-action btn btn-success text-white " onClick={() => setFixed(row.id,row.url)}>
                            <Check2 className="" size={20} /></button>
                             <button type="button" title="Ignore" className="p-1 button-action btn btn-secondary text-white " onClick={() => setIgnored(row.id,row.url)}>
                             <EyeSlashFill className="" size={20} /></button></div>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },


    ];
 
    const [dataApi, setDataApi] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = (dataApi.length>0) ? dataApi.filter(item => item.url && item.url.toLowerCase().includes(filterText.toLowerCase())) : [];
    const filteredItems2 = (dataApi.length>0) ? dataApi.map(item => {
        return (
        {
            created:item.created,
            url:item.url,
            page: item.page.url,
        })
        
    }) : [];
    const [selectedWebsites, setSelectedWebsites] = useState([]);
    const[technicalDebt, setTechnicalDebt] = useState()
    const[websiteUser,setWebsiteUser] = useState(localStorage.getItem("websiteSelected") ? localStorage.getItem("websiteSelected") : "")
    const[pendingLoading,setPendingLoading]=useState(true)
    
    useEffect(() => {
        setPendingLoading(true)
        handleData()
    }, [selectedWebsites.length,websiteUser])

    const handleWebsite = async() => {
        try{
            const {data:dataWebsites} = await axiosUserLog().get("websites/?website=pk,start_url&limit=100");
            setSelectedWebsites(dataWebsites.results)
        }catch(err){
            console.log(err)
        }
    };

    const handleData = async () => {
        try{
            if(websiteUser!=""){
                const {data} = await handleGetData("brokenlinks/?link=id,url,created,page,hint_proxy&page=id,url,website&website=pk,start_url&limit=100", history)
                const {data:technicalDebt} = await handleGetData("technicaldebt/")
                setTechnicalDebt(technicalDebt)
                setDataApi(data.results.filter(blink=>blink.page.website.pk==websiteUser))
                setPendingLoading(false)
            }
            await handleWebsite()
        }catch(err){
            console.log(err)
        }
    }

    const setFixed = async (pk,url) => {
        customConfirmMessage("warning",`Are you sure to move the Link (${url}) to fixed links?`,"",async()=>{
            try{
                await axiosUserLog().patch("fixedlinks/",{"id": pk,"fixed": 'True'})
                customBasicMessageToast(1500,"success","Link moved to fixed links!")
                await handleData()
            }catch(error){
                if (error.response.status === 400) {
                    customBasicMessageModal("error","There was an error!","The link could not return! Please try again")
                }
                if (error.response.status === 401) {
                    customBasicMessageModal("error","There was an error!","Please try again")
                    await handleData()
                }
            }        
        })
    }

    const setIgnored = async (pk,url) => {
        customConfirmMessage("warning",`Are you sure to move the Link (${url}) to ignored links?`,"",async()=>{
            try{
                await axiosUserLog().patch(`brokenlinks/${pk}/`,{ ignored: true })
                customBasicMessageToast(1500,"success","Link moved to ignored links!")
                await handleData()
            }catch(error){
                if (error.response.status === 400) {
                    customBasicMessageModal("error","There was an error!","The link could not return! Please try again")
                }
                if (error.response.status === 401) {
                    customBasicMessageModal("error","There was an error!","Please try again")
                    await handleData()
                }
            }
        })
    }

    const handleCreate = async(row, url) => {
        try{
            await axiosUserLog().post("hints/",
                {
                    "website": row.page.website.pk,
                    "fixed_url": url,
                    "broken_url": row.url,
                }
            )
            customBasicMessageToast(1500,"success","Hint successfully created")
            await handleData()
        }catch(error){
            if (error.response.status === 400) {
                customBasicMessageModal("error","There was an error!","Please re-check your form and try again")
            }
            if (error.response.status === 401) {
                customBasicMessageModal("error","There was an error!","Please try again")
                await handleData()
            }
        }
    };

    const handleUpdate = async (row, url) => {
        try {
            await axiosUserLog().patch(`hints/${row.hint_proxy}/`, {
                "fixed_url": url,
            })
            customBasicMessageToast(1500,"success","Hint successfully updated")
            await handleData()
        } catch (error) {
            if (error.response.status === 400) {
                customBasicMessageModal("error","There was an error!","Please re-check your form and try again")
            }
            if (error.response.status === 401) {
                customBasicMessageModal("error","There was an error!","Please try again")
                await handleData()
            }
        }
    };
    const handleDeleteOne = async pk => {
        customConfirmMessage("warning","Are you sure you want to delete ?","",async()=>{
            try{
                await axiosUserLog().delete(`hints/${pk}/`);
                customBasicMessageToast(1500,"success","Hint successfully removed")
                await handleData()
            }catch(err){
                console.log(err)
            }
        })
    }
    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent placeholder="Filter by Broken Link" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);
    console.log(filteredItems)
    return (
        <div id="content" className="p-md-5 container-fluid">
            <Helmet>
                <title>Broken Links</title>
            </Helmet>
            <div className="d-flex flex-column" id="content-wrapper">
                <div className="row gradient-card-header  py-2 mx-4 mb-3 d-flex">
                        <SelectWebsite data={selectedWebsites} setWebsiteUser={setWebsiteUser}/>
                </div>
                <div className="card card-cascade narrower  border-0 ">
                    <div className="row gradient-card-header  py-2 mx-4 mb-3 d-flex d-block">
                        <h1 className="col-12  text-secondary   col-6 rounded">Broken Links</h1>
                        <h6 className="col-12  text-secondary  bg-light-blue p-3 col-6 rounded">
                            Your Broken Links. Add your own hints to improve the quality of the recommendation system.
                            <br></br>For your convenience, only the first 100 or less Broken Links are shown.
                        </h6>
                    </div>
                    <div className="row gradient-card-header  py-2 mx-4 mb-3 d-flex d-block">
                        {
                            technicalDebt
                            ?
                            <>
                                <h6 className="col-12  text-info  bg-light-blue p-3 col-6 rounded">
                                The technical debt to resolve these broken links is of {searchTechnicalDebt(technicalDebt,'broken_link')*filteredItems.length} minutes
                                </h6>
                               
                            </>
                            :
                            ""
                        }
                    </div>
                    <div className="px-4">
                        {
                            websiteUser!=""
                            ?
                            <>
                            {
                                    !pendingLoading
                                    ?
                                    <CsvButton data={filteredItems2} name={`Broken Links ${websiteUser}`}/>
                                    :
                                    ""
                                }
                                <DataTable
                                    columns={columns}
                                    data={filteredItems}
                                    keyField={'pk'}
                                    highlightOnHover
                                    pointerOnHover
                                    customStyles={customStyles}
                                    pagination
                                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                    subHeader
                                    subHeaderComponent={subHeaderComponentMemo}
                                    persistTableHead
                                    progressPending={pendingLoading}
                                    progressComponent={<TableLoader/>}
                                />
                            </>
                            :
                            <h4 className="text-center">Select a website to continue</h4>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TableBroken;