import React, { Fragment, useEffect, useState } from "react"

import { Link, useHistory } from "react-router-dom";
import { Bag, Gear } from "react-bootstrap-icons";
import { handleGetData } from "../helpers/requests";
import { Helmet } from "react-helmet";


const Upgrade = () => {

    let history = useHistory();
    const [data, setData] = useState([]);

    useEffect(() => {
        handleData()
    }, [])

    const handleData = async() => {
        try{
            const {data} = await handleGetData("account/",history)
            console.log(data)
            setData(data)
        }catch(err){
            console.log(err)
        }
    }

    return (
        <Fragment>
            <Helmet>
                <title>Account Details</title>
            </Helmet>
            <div id="wrapper " className=" mt-2 p-md-5 bg-light-blue">
                <div className="d-flex flex-column " id="content-wrapper">
                    <div id="content">
                        <div className="row upgrade-row justify-content-center">
                            <div className="card col-10 col-sm-9 col-md-7 col-lg-5 shadow m-1">
                                <div className="card-header bg-white">
                                    <h3 className="card-title text-dark text-center">Account information</h3>
                                </div>
                                <div className="card-body">
                                    <table className="table table-striped text-center">

                                        <tbody>
                                            <tr>
                                                <th>Email</th>
                                                <th>{data.user ? data.user.email : ''}</th>
                                            </tr>
                                            <tr>
                                                <td>Sites</td>
                                                <td> {data.websites_count ? data.websites_count : ''}</td>
                                            </tr>
                                            <tr>
                                                <td>Pages</td>
                                                <td>{data.pages_count ? data.pages_count : ''}</td>
                                            </tr>
                                            <tr>
                                                <td>Expiration</td>
                                                <td>{data?.plan?.valid_until ? new Date(data?.plan?.valid_until).toLocaleDateString('es-ES') : 'Unlimited'}</td>
                                            </tr>
                                            <tr>
                                                <td>Plan</td>
                                                <td>{data?.plan?.enabled ? `$${data.plan.cost}` : 'Free'}</td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer bg-white">
                                    <div className="row forgot">
                                        <div className="col-6 ">
                                            <Link to="/main/settings" className="btn btn-secondary col-12 ">
                                                <Gear className="" size={20} /> Settings </Link>
                                        </div>
                                        <div className="col-6">
                                            <Link to="/main/upgrade" className="btn btn-success col-12 ">
                                                <Bag className="mx-1" size={22} /> RENEW NOW! </Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Upgrade;