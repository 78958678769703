import React from "react";
import "./Characteristics.css";
import CharacteristicsCard from "./CharacteristicsCard";
const Characteristics = () => {
  return (
    <>
      <div
        className="main-section landing-container characteristics-container"
        id="characteristics"
      >
        <h2 className="section-title">Characteristics</h2>
        <div className="characteristics-cards-container">
          <CharacteristicsCard
            imgCard="/assets/blogging.png"
            altImg="Accessibility feature"
            textCard="Break barriers, reach everyone. Discover the accessibility your
              website needs."
          />
          <CharacteristicsCard
            imgCard="/assets/search_engines.png"
            altImg="SEO feature"
            textCard="Boost your online visibility. Enhance your SEO and reach the top
            of search results."
          />
          <CharacteristicsCard
            imgCard="/assets/control_panel.png"
            altImg="Statistics feature"
            textCard="Visualize your progress with clarity. Stunning graphs that
            showcase the success of your website."
          />
        </div>
      </div>
    </>
  );
};

export default Characteristics;
