import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import ReactModal from 'react-modal';
import { useModal, ModalProvider } from "react-modal-hook";
import { Link, useHistory } from 'react-router-dom';
import differenceBy from 'lodash/differenceBy';
import { Pencil, Trash } from 'react-bootstrap-icons';
import { axiosUserLog } from '../helpers/axiosInstance';
import { handleGetData } from '../helpers/requests';
import { customStyles } from '../helpers/tableStyles';
import FilterComponent from './FilterComponent';
import { customBasicMessageModal, customBasicMessageToast, customConfirmMessage } from '../helpers/messages';
import { Helmet } from 'react-helmet';
import { calculateTotalTechnicalDebt } from '../helpers/utils';

const Create = (props) => {
    const websiteRef = useRef(null);
    const [showModal, hideModal] = useModal(() => (
        <ReactModal isOpen className="p-3 mx-auto bg-white border col-10 col-md-5 mt-lg-5" ariaHideApp={false}>
            <form className="row" onSubmit={(e)=>{
                e.preventDefault()
                hideModal();
                props.create(props.row, websiteRef.current.value);
            }}>
                <div className="col-12 form-group">
                    <button type="button" className="close" aria-label="Close" onClick={hideModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h3 className="text-center text-secondary" >{props.header}</h3>
                </div>
                <div className="col-12 form-group pb-3" >
                    <label >Website:</label>
                    <input type="url" ref={websiteRef} className="form-control" id="inputWebsite" placeholder="Enter Website" name="website" defaultValue={(props.row) ? props.row.start_url : ''} required />
                </div>
                
                {
                    /*
                    <>
                    <div className="col-12 form-group border-top pt-3" >
                        <h4 className="text-center text-secondary">Credentials (optional)</h4>
                        <label >User:</label>
                        <input type="text" className="form-control" id="UserCredential" placeholder="Enter user" name="userCredential" disabled/>
                    </div>
                    <div className="col-12 form-group " >
                        <label >Password:</label>
                        <input type="password" className="form-control" id="PasswordCredential" placeholder="Enter password" name="passwordCredential" disabled/>
                    </div>
                    </>
                    */
                }
                <div className="col-6 small">
                    <button type="button" className="btn btn-secondary col-12 " onClick={hideModal}>Close</button>
                </div>
                <div className="col-6 small">
                    <button type="submit" className="btn btn-success col-12 ">Save</button>
                </div>
            </form>
        </ReactModal>
    ));
    return <button onClick={showModal} className={props.className} >{(props.title) ? 'Add Website' : <Pencil className="" size={25} />}</button>;
};


const TableWebsite = () => {
    let history = useHistory();
    const columns = [
        {
            cell: row => <a href={row.start_url} className="mt-3">{row.start_url}</a>,
            name: 'Website',
            selector: 'start_url',
            sortable: true,
        },
        {
            name: 'Feeds',
            selector: 'feeds',
            sortable: true,
            grow: 0.2,
        },
        {
            name: 'Pages',
            selector: 'pages',
            sortable: true,
            grow: 0.2,
        },
        {
            name: 'Broken Links',
            selector: 'broken_links',
            sortable: true,
            grow: 0.2,
        },
        {
            name: 'Technical Debt (minutes)',
            selector: 'totalTechnicalDebt',
            sortable: true,
            grow: 0.2,
        },
        {
            name: 'Actions',
            cell: row =>
                <div>
                    <ModalProvider>
                        <Create header="Edit Website" icon="action icon ion-md-create" className="p-1 ml-1 btn btn-secondary " row={row} create={handleUpdate} />
                    </ModalProvider>
                    <button type="button" title="delete" className="p-1 ml-1 btn btn-danger text-white " onClick={() => handleDeleteOne(row)}>
                        <Trash className="" size={25} />
                    </button>
                </div>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [dataWebsites, setDataWebsites] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = (dataWebsites.length>0) ? dataWebsites.filter(item => item.start_url && item.start_url.toLowerCase().includes(filterText.toLowerCase())) : [];
   
    useEffect(() => {
        handleGetWebsites()
    }, [])

    const handleGetWebsites=async()=>{
        try{
            const {data} = await handleGetData("websites/?limit=100",history);
            const {data:technicalDebt} = await handleGetData("technicaldebt/")
            console.log(data)
            console.log(technicalDebt)
            let totalTechnicalDebt = 0;
            
            let dataWebsitesComplete = data.results.map(website=>{
                return ({
                    ...website,
                    totalTechnicalDebt: calculateTotalTechnicalDebt(website, technicalDebt)
                })

            })
            setDataWebsites(dataWebsitesComplete)
        }catch(err){
            console.log(err)
        }
    }

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const contextActions = useMemo(() => {
        const handleDelete = async () => {
            customConfirmMessage("warning",`Are you sure you want to delete:`,`${selectedRows.map(r => r.start_url)}`,async()=>{
                setToggleCleared(!toggleCleared);
                for (let i = 0; i < selectedRows.length; i++) {
                    await axiosUserLog().delete(`websites/${selectedRows[i].pk}/`);
                    localStorage.removeItem("websiteSelected")
                }
                customBasicMessageToast(1500,"success","Websites successfully removed")
                setDataWebsites(differenceBy(dataWebsites, selectedRows, 'pk'))  
            })
        };
        return <Link to="/main/websites" key="delete" className="btn btn-danger" onClick={handleDelete} >Delete</Link>;
    }, [dataWebsites, selectedRows, toggleCleared]);

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
        return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholder="Filter By Website" />;
    }, [filterText, resetPaginationToggle]);

    const tldjs = require('tldjs');
    const { getDomain } = tldjs;

    const handleCreate = async (row, url) => {
        try {
            await axiosUserLog().post("websites/", {
                "enabled": true,
                "start_url": url,
                "domain": getDomain(url),
                "account": localStorage.getItem('userId'),
            })
            customBasicMessageToast(8500,"success","The website has been successfully added and is being analyzed right now. You will receive an email when this process is finished.")
            await handleGetWebsites()
        } catch (error) {
            if (error.response.status === 400) {
                if(error?.response?.data?.start_url[0] === "Enter a valid URL."){
                    customBasicMessageModal("error","Insert a valid website to continue", `${error?.response?.data?.start_url[0].replace(".","")} like https://www.mywebsite.com`)
                }else{
                    customBasicMessageModal("error","Insert a valid website to continue",error?.response?.data?.start_url[0])    
                }
            }
            if (error.response.status === 401) {
                customBasicMessageModal("error","Error when creating a new website","Try again")
                await handleGetWebsites()
            }
        }
    };
    const handleUpdate = async (row, url) => {
        try {
            await axiosUserLog().patch(`websites/${row.pk}/`, {
                "start_url": url,
                "domain": getDomain(url),
            })
            customBasicMessageToast(1500,"success","Website successfully updated")
            await handleGetWebsites()
        } catch (error) {
            if (error.response.status === 400) {
                if(error?.response?.data?.start_url[0] === "Enter a valid URL."){
                    customBasicMessageModal("error","Insert a valid website to continue", `${error?.response?.data?.start_url[0].replace(".","")} like https://www.mywebsite.com`)
                }else{
                    customBasicMessageModal("error","Insert a valid website to continue",error?.response?.data?.start_url[0])    
                }
            }
            if (error.response.status === 401) {
                customBasicMessageModal("error","Error when updating the website","Try again")
                await handleGetWebsites()
            }
        }
    };
    const handleDeleteOne = async row => {
        customConfirmMessage("warning",`Are you sure you want to delete: ${row.start_url}?`,"This action cannot be undone",async()=>{
            try {
                await axiosUserLog().delete(`websites/${row.pk}/`);
                setDataWebsites(dataWebsites.filter(domain => domain.pk !== row.pk))
                localStorage.removeItem("websiteSelected")
                customBasicMessageToast(1500,"success","Website successfully removed")
            } catch (error) {
                customBasicMessageModal("error","Error when deleting the website","Try again")
            }
        })
    }
    const actions = <ModalProvider><Create title="Website" icon="action icon ion-md-add pr-1" className=" text-center btn btn-success " header="Add website" create={handleCreate} /></ModalProvider>;
    return (
        <div id="content" className="p-md-5 container-fluid">
            <Helmet>
                <title>Websites</title>
            </Helmet>
            <div className="d-flex flex-column" id="content-wrapper">
                <div className="card card-cascade narrower  border-0 ">
                    <div className="row gradient-card-header  py-2 mx-4 mb-3 d-flex d-block">
                        <h1 className="col-12  text-secondary   col-6 rounded">Websites</h1>
                        <h6 className="col-12  text-secondary  bg-light-blue p-3 col-6 rounded">Your Websites.</h6>
                    </div>
                    <div className="row  px-4">
                    </div>
                    <div className="px-4">
                        <DataTable
                            columns={columns}
                            data={filteredItems}
                            keyField={'pk'}
                            highlightOnHover
                            pointerOnHover
                            customStyles={customStyles}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            selectableRows
                            persistTableHead
                            actions={actions}
                            contextActions={contextActions}
                            onSelectedRowsChange={handleRowSelected}
                            clearSelectedRows={toggleCleared}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TableWebsite;