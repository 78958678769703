import React, { useEffect, useMemo, useState } from "react"
import DataTable from "react-data-table-component"
import { useHistory, useParams } from "react-router"
import { Link } from "react-router-dom"
import { handleGetData } from "../helpers/requests"
import { customStyles } from "../helpers/tableStyles"
import { accesibilityIssues, searchAttributeIssue, searchNameIssue, seoIssues } from "../helpers/utils"
import FilterComponent from "./FilterComponent"
import TableLoader from "./TableLoader"
import { Helmet } from "react-helmet"
import CsvButton from "./CsvButton"
const IssueSeoDetails=()=>{
    const {issue,idWebsite} = useParams()
    console.log(issue)
    const history = useHistory()
    const [dataWebsite,setDataWebsite]=useState([])
    const [pendingLoading,setPendingLoading]=useState(true)
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [nameWebsite,setNameWebsite]=useState("")
    const filteredItems = (dataWebsite.length>0) ? dataWebsite.filter(item => item.page.url && item.page.url.toLowerCase().includes(filterText.toLowerCase())) : [];
    const filteredItems2 = (dataWebsite.length>0) ? dataWebsite.map(item => {
        return (
        {
            created:item.created,
            issue:issue,
            page: item.page.url,
            count:item.count
        })
        
    }) : [];
    useEffect(()=>{
        if(localStorage.getItem(issue)){
            const pagesStorage = JSON.parse(localStorage.getItem(issue))
            setNameWebsite(pagesStorage[0].page.website.start_url)
            setDataWebsite(pagesStorage)
            setPendingLoading(false) 
        }else{
            handleData()
        }
    },[])
    const handleData = async() => {
        try{
            const {data} = await handleGetData(`${searchAttributeIssue("url",issue, seoIssues)}/?idWebsite=${idWebsite}`,history)
            console.log(data)
            
            localStorage.setItem(issue,JSON.stringify(data))
            setNameWebsite(data[0].page.website.start_url)
            setDataWebsite(data)
            setPendingLoading(false)    
            console.log(dataWebsite)
        }catch(err){
            console.log(err)
        }
    }
    const columns = [

        {
            cell: row=><div className="my-3">
                    <Link to="#">
                    <p className="mb-1 h5 text-primary font-weight-bold">{row.page.title}</p>
                    {row.page.url}</Link>
                </div>,
            name: 'Page',
            selector:'url',
            sortable: true,
            grow:2
        },
        {
            name:'Count',
            selector:`${searchAttributeIssue("countSelector",issue, seoIssues)}`,
            sortable:true,
            grow:0.2,
        },
        {
            cell: row=><a href={row.page.url} target="_blanck">View page</a>,
            name:'View',
            grow:0.2
        }
    ]

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent placeholder="Filter by Page" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    console.log(filteredItems)
    console.log(filteredItems2)
    return(
        <div id="content" className="p-md-5 container-fluid">
            <Helmet>
                <title>SEO Issue Details</title>
            </Helmet>
            <div className="d-flex flex-column" id="content-wrapper">
                <h1 className="col-12  text-secondary col-6 rounded">{searchNameIssue(issue, seoIssues)} on <a href={nameWebsite} target="_blanck">{nameWebsite}</a></h1>
                <div className="d-flex flex-wrap mt-4">
                    <div className="skeleton-loader"></div>
                    <div className={`card col-sm-12 col-xl-4 p-4 mx-4 my-2 ${pendingLoading ? "skeleton-loader" : "bg-light"}`}>
                        {
                            !pendingLoading
                            ?
                            <>
                                <div className="card-header card-header-issue"><h4 className="font-weight-bold">Issue description</h4></div>
                                <div className="card-body" dangerouslySetInnerHTML={{__html:searchAttributeIssue("description",issue, seoIssues)}}/>
                                <div className="card-footer"><a href={searchAttributeIssue("documentation",issue, seoIssues)} target="_BLANCK" className="btn btn-primary">Learn more</a></div>
                            </>
                            :
                            ""
                            }
                    </div>
                    <div className={`card col-sm-12 col-xl-6 p-4 mx-4 my-2 ${pendingLoading ? "skeleton-loader" : "bg-light"}`}>
                        {
                            !pendingLoading
                            ?
                            <>
                                <div className="card-header card-header-issue"><h4 className="font-weight-bold">Example solutions to this issue</h4></div>
                                <div className="card-body" dangerouslySetInnerHTML={{__html:searchAttributeIssue("tips",issue, seoIssues)}}/>
                            </>
                            :
                            ""
                            }
                    </div>
                </div>
                <div className="px-4 d-flex flex-column">
                        {
                            !pendingLoading
                            ?
                                <CsvButton data={filteredItems2} name={`${searchNameIssue(issue, accesibilityIssues)} on ${nameWebsite}`}/>
                            :
                            ""
                        }
                        <DataTable
                            columns={columns}
                            data={filteredItems}
                            keyField={'pk'}
                            highlightOnHover
                            pointerOnHover
                            customStyles={customStyles}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            progressPending={pendingLoading}
                            progressComponent={<TableLoader/>}
                        />
                </div>
            </div>
        </div>
    )
}

export default IssueSeoDetails