import React, { Fragment, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import PageContent from './PageContent';
import Footer from './Footer';
import { Route, Switch } from 'react-router-dom';
import Broken from './TableBroken'
import Fixed from './TableFixed'
import Ignored from './TableIgnored';
import Websites from './TableWebsites';
import Pages from './TablePages';
import Risk from './TableRisk';
import Upgrade from './TableUpgrade';
import AccountInfo from './AccountInfo';
import Settings from './Settings';
import RiskDomainLinks from './TableRiskLinks';
import Feeds from './TableFeeds';
import  AccesibilityDashboard from "./AccesibilityDashboard";
import IssueAccesibilityDetails from "./IssueAccesibilityDetails";
import AccesibilityPageDetails from "./AccesibilityPageDetails";
import SeoDashboard from "./SeoDashboard";
import IssueSeoDetails from "./IssueSeoDetails";
import { Helmet } from "react-helmet";
import SummaryPurchase from "./SummaryPurchase";
import MisspellingsDashboard from "./MisspellingsDashboard";
import MisspellingsPageDetails from "./MisspellingsPageDetails";
import WebPageViewerErrors from "./WebPageViewerErrors";

const Layout = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = useState(true)

    const openHandler = () => {
        if (!sidebarOpen) {
            setSidebarOpen(true)
        } else {
            setSidebarOpen(false)
        }
    }

    const sidebarCloseHandler = () => {
        setSidebarOpen(false)
    }

    let sidebar
    if (sidebarOpen) {
        sidebar = <Sidebar close={sidebarCloseHandler} sidebar={"sidebar"} />
    }

    return (
        <Fragment>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <div className="d-flex app-container" id="content-wrapper">
                {sidebar}
                <div id="page-content-wrapper" className={`w-100 bg-white ${sidebarOpen ? "content-side-open" : "content-side-close"}`}>
                    <Header click={openHandler}></Header>

                    <Switch>
                        <Route exact path='/main' component={PageContent} />
                        <Route path='/main/settings' component={Settings} />
                        <Route path='/main/websites' component={Websites} />
                        <Route path='/main/accesibility' component={AccesibilityDashboard} />
                        <Route path='/main/seo' component={SeoDashboard} />
                        <Route path='/main/misspellings' component={MisspellingsDashboard} />
                        <Route path='/main/feeds' component={Feeds} />
                        <Route path='/main/pages' component={Pages} />
                        <Route path='/main/riskdomain' component={Risk} />
                        <Route path='/main/brokenlinks' component={Broken} />
                        <Route path='/main/fixedlinks' component={Fixed} />
                        <Route path='/main/ignoredlinks' component={Ignored} />
                        <Route path='/main/risklinks' component={RiskDomainLinks} />
                        <Route path='/main/upgrade' component={Upgrade} />
                        <Route path='/main/accountinfo' component={AccountInfo} />
                        <Route path='/main/misspellingsdetails/:idPage' component={MisspellingsPageDetails} />
                        <Route path='/main/accesibilityissue/:issue/:idWebsite' component={IssueAccesibilityDetails} />
                        <Route path='/main/accesibilitydetails/viewerpage' component={WebPageViewerErrors} />
                        <Route path='/main/seoissue/:issue/:idWebsite' component={IssueSeoDetails} />
                        <Route path='/main/accesibilitydetails/:issue/:idPage' component={AccesibilityPageDetails} />
                        <Route path='/main/summary-purchase/:id' component={SummaryPurchase} />
                        <Route component={PageContent} />
                    </Switch>

                    <Footer></Footer>
                </div>

            </div>

        </Fragment>
    )
}

export default Layout