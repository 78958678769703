import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { EnvelopeFill } from 'react-bootstrap-icons';
import Icon from '../img/icon.png';
import axiosInstance from '../helpers/axiosInstance';
import { customBasicMessageModal } from '../helpers/messages';

const ForgotPassword = () => {
    const[emailUser,setEmailUser]=useState("");
    useEffect(()=>{
        document.title="Recover your password"
    },[])
    const onSubmit =async (e) => {
        e.preventDefault();
        console.log(emailUser)
        try {
            await axiosInstance.post("password-recovery/", {
                "email": emailUser,             
            })
            customBasicMessageModal("info","Recover your password","An email has been sent to you to reset your password.")
        } catch (error) {
            customBasicMessageModal("error","Wrong email","Verify the email and try again.")
        }
    }
    return (
        <div className="container ">
            <div className="row justify-content-center">
                <div className="col col-md-8 pt-md-5">
                    <div className="card  my-5 shadow-sm shadow-hover">
                        <h1 className='text-center text-secondary mt-4'>Recover your password</h1>
                        <div className="col-12 user-img my-4">
                            <img src={Icon} alt="Logo bye bye 404" className="d-block mx-auto "></img>
                        </div>
                        <div className="card-body p-0 ">
                            <div className="row">
                                <div className="col">
                                    <div className="px-5 pb-5">
                                        <div className="text-center">
                                            <h4 className="text-dark mb-2">Forgot Your Password?</h4>
                                            <p className="mb-4">We get it, stuff happens. Just enter your email address below and we'll send you a link to reset your password!</p>
                                        </div>
                                        <form className="forgot" onSubmit={onSubmit}>

                                            <div className="row form-group">
                                                <label htmlFor="InputEmail" className="col-12 text-left">Email Address:</label>
                                                <EnvelopeFill className="col-1 mx-1 d-flex" size={40} />
                                                <input className="col-10 form-control form-control-user " onChange={(e)=>setEmailUser(e.target.value)} type="email" id="InputEmail" aria-describedby="emailHelp" placeholder="Enter Email Address..." name="email" required />
                                            </div>
                                            <button className="btn btn-success btn-block text-white btn-user"
                                                type="submit">Reset Password</button></form>
                                        <div className="text-center">
                                            <hr /><Link className=" text-primary" to="/signup">Create an Account!</Link>
                                        </div>
                                        <div className="text-center ">
                                            <Link className=" text-primary" to="/login">Already have an account? Login!</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;