import React from "react";
const CharacteristicsCard = ({ imgCard, altImg, textCard }) => {
  return (
    <>
      <div className="characteristics-card">
        <img src={imgCard} alt={altImg} />
        <p>{textCard}</p>
      </div>
    </>
  );
};

export default CharacteristicsCard;
