import axiosInstance, { axiosUserLog } from "./axiosInstance";
import { customBasicMessageModal, customBasicMessageToast } from "./messages";

export const handleGetData = async(url,history) => {
    try{
        const response = await axiosUserLog().get(url);
        return response
    }catch(err){
        try{
            const {data} = await axiosInstance.post("auth/token/refresh",{
                "refresh": localStorage.getItem('tokenRefresh')
            });
            localStorage.setItem("token", data.access)
            try{
                const response2 = await axiosUserLog().get(url);
                return response2
            }catch(err3){
                console.log("Error: ", err3)
            }
        }catch(err2){
            customBasicMessageModal("warning","Log in to continue","")
            history.push("/login");
            localStorage.clear();
        }
    }
}