import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { ArrowBarLeft } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import FilterComponent from './FilterComponent';
import { customStyles } from '../helpers/tableStyles';
import { errors } from '../helpers/utils';
import {handleGetData} from "../helpers/requests"
import { axiosUserLog } from '../helpers/axiosInstance';
import { customBasicMessageModal, customBasicMessageToast, customConfirmMessage } from '../helpers/messages';
import { SelectWebsite } from './SelectWebsite';
import TableLoader from './TableLoader';
import { Helmet } from 'react-helmet';
const TableIgnored = () => {
    let history = useHistory();
    const columns = [
        {
            cell: row => <a href={row.page} className="mt-3">{row.page.url}</a>,
            name: 'Page',
            sortable: true,
        },
        {
            cell: row => <a href={row.url} className="mt-3">{row.url}</a>,
            name: 'Link',
            selector: 'url',
            sortable: true,
        },
        {
            cell: row => <p className="mt-3">{row.text}</p>,
            name: 'Text',
            selector: 'text',
            sortable: true,

        },
        {
            cell: row => <p className="mt-3">{changeError(row.response)}</p>,
            name: 'Error',
            selector: 'response',
            sortable: true,
            grow: 0.3
        },
        {
            name: 'Actions',
            cell: row => <button type="button" title="refund" className="p-1 button-action btn btn-danger text-white " onClick={() => setLink(row.id, row.url)}>
                <ArrowBarLeft className="" size={20} /></button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const [dataApi, setDataApi] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = (dataApi.length>0) ? dataApi.filter(item => item.url && item.url.toLowerCase().includes(filterText.toLowerCase())) : [];
    const [selectedWebsites, setSelectedWebsites] = useState([]);
    const[websiteUser,setWebsiteUser] = useState(localStorage.getItem("websiteSelected") ? localStorage.getItem("websiteSelected") : "")
    const[pendingLoading,setPendingLoading]=useState(true)
    
    useEffect(() => {
        setPendingLoading(true)
        handleData()
    }, [selectedWebsites.length,websiteUser])

    const handleWebsite = async() => {
        try{
            const {data:dataWebsites} = await axiosUserLog().get("websites/?website=pk,start_url&limit=100");
            setSelectedWebsites(dataWebsites.results)
        }catch(err){
            console.log(err)
        }
    };

    const handleData = async () => {
        try{
            if(websiteUser!=""){
                const {data} = await handleGetData("ignoredlinks/?link=id,url,text,response,page&page=id,url,website&website=pk,start_url&limit=100", history)
                console.log(data)
                setDataApi(data.results.filter(ilink=>ilink.page.website.pk==websiteUser))
                setPendingLoading(false)
            }
            await handleWebsite()
        }catch(err){
            console.log(err)
        }
    }

    function changeError(error) {
        let temp = 'none';
        for (let i = 0; i < errors.length; i++) {
            if (error === errors[i][0]) {
                temp = errors[i][1]
                break;
            }
        }
        return temp
    }

    const setLink = async (pk, url) => {
        customConfirmMessage("warning",`Are you sure to return the link: ${url}`,"",async()=>{
            try{
                await axiosUserLog().patch(`ignoredlinks/${pk}/`, { ignored: false })
                customBasicMessageToast(1500,"success","Link successfully returned")
                await handleData()
            }catch(error){
                if (error.response.status === 400) {
                    customBasicMessageModal("error","There was an error!","The link could not return! Please try again")
                }
                if (error.response.status === 401) {
                    customBasicMessageModal("error","There was an error!","Please try again")
                    await handleData()
                }
            }
        })

    }
    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent placeholder="Filter by Link" onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    return (
        <div id="content" className="p-md-5 container-fluid">
            <Helmet>
                <title>Ignored Links</title>
            </Helmet>
            <div className="d-flex flex-column" id="content-wrapper">
                <div className="row gradient-card-header  py-2 mx-4 mb-3 d-flex">
                        <SelectWebsite data={selectedWebsites} setWebsiteUser={setWebsiteUser}/>
                </div>
                <div className="card card-cascade narrower  border-0 ">
                    <div className="row gradient-card-header  py-2 mx-4 mb-3 d-flex d-block">
                        <h1 className="col-12  text-secondary   col-6 rounded">Ignored links</h1>
                        <h6 className="col-12  text-secondary  bg-light-blue p-3 col-6 rounded">Your Ignored links.</h6>
                    </div>
                    <div className="row  px-4">

                    </div>
                    <div className="px-4">
                        {
                            websiteUser!=""
                            ?
                            <DataTable
                                columns={columns}
                                data={filteredItems}
                                keyField={'pk'}
                                highlightOnHover
                                pointerOnHover
                                customStyles={customStyles}
                                pagination
                                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                                persistTableHead
                                progressPending={pendingLoading}
                                progressComponent={<TableLoader/>}
                            />
                            :
                            <h4 className="text-center">Select a website to continue</h4>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TableIgnored;