import React, {  Fragment } from 'react'
import Layout from '../components/Layout'


const Dashboard = () => {

    return ( 
        <Fragment>
        <Layout></Layout>
        
        </Fragment>
     );
}
 
export default Dashboard;


