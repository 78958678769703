import Swal from "sweetalert2";

export const customBasicMessageModal=(type,title,text)=>{
    return Swal.fire({
        icon:type,
        type,
        title,
        text
    })
}

export const customBasicMessageToast=(timer=2500,type,title)=>{
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      return Toast.fire({
        icon: type,
        title,
      })
}

export const customConfirmMessage=(type,title,text,action)=>{
    return Swal.fire({
        title,
        text,
        icon: type,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          action();
        }
      });
}