import Moment from 'moment';
export const changeDate = (date)=>{
    const temp=Moment(date).format('YYYY-MM-DD');
    return temp
}
export const errors = [
    ['404', 'Not Found'],
    ['200', 'OK'],
    ['100', 'Continue'],
    ['101', 'Switching Protocols'],
    ['102', 'Processing'],
    ['103', 'Checkpoint'],
    ['201', 'Created'],
    ['202', 'Accepted'],
    ['203', 'Non-Authoritative Information'],
    ['204', 'No Content'],
    ['205', 'Reset Content'],
    ['206', 'Partial Content'],
    ['207', 'Multi-Status'],
    ['208', 'Already Reported'],
    ['300', 'Multiple Choices'],
    ['301', 'Moved Permanently'],
    ['302', 'Found'],
    ['303', 'See Other'],
    ['304', 'Not Modified'],
    ['305', 'Use Proxy'],
    ['306', 'Switch Proxy'],
    ['307', 'Temporary Redirect'],
    ['308', 'Permanent Redirect'],
    ['400', 'Bad Request'],
    ['401', 'Unauthorized'],
    ['402', 'Payment Required'],
    ['403', 'Forbidden'],
    ['405', 'Method Not Allowed'],
    ['406', 'Not Acceptable'],
    ['407', 'Proxy Authentication Required'],
    ['408', 'Request Timeout'],
    ['409', 'Conflict'],
    ['410', 'Gone'],
    ['411', 'Length Required'],
    ['412', 'Precondition Failed'],
    ['413', 'Request Entity Too Large'],
    ['414', 'Request-URI Too Long'],
    ['415', 'Unsupported Media Type'],
    ['416', 'Requested Range Not Satisfiable'],
    ['417', 'Expectation Failed'],
    ['418', "I'm a teapot"],
    ['421', 'Misdirected Request'],
    ['422', 'Un-processable Entity'],
    ['423', 'Locked'],
    ['424', 'Failed Dependency'],
    ['425', 'Unassigned'],
    ['426', 'Upgrade Required'],
    ['428', 'Precondition Required'],
    ['429', 'Too Many Requests'],
    ['431', 'Request Header Fileds Too Large'],
    ['449', 'Retry With'],
    ['451', 'Unavailable for Legal Reasons'],
    ['500', 'Internal Server Error'],
    ['501', 'Not Implemented'],
    ['502', 'Bad Gateway'],
    ['503', 'Service Unavailable'],
    ['504', 'Gateway Timeout'],
    ['505', 'HTTP Version Not Supported'],
    ['506', 'Variant Also Negotiates'],
    ['507', 'Insufficient Storage'],
    ['508', 'Loop Detected'],
    ['510', 'Not Extended'],
    ['511', 'Network Authentication Required'],
];


export const accesibilityIssues=[
    {
        id:"image_without_alt",
        url:"imagewithoutalt",
        name:"Image missing a text alternative",
        level: "A",
        description:`
            <p className="card-text">Adding alternative text to images is important for visually impaired people to understand the content of a web page. Alternative text describes the content of the image and its function, which helps users understand how it relates to the surrounding content. If an image does not have alt text, visually impaired users may miss important information and the page may be less accessible to all users. Therefore, adding alternative text is critical to ensure accessibility and understanding of the web page for all people.</p>
        `,
        tips:
            `
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Add the <code>alt</code> attribute in the <code><img></code> tag.</h5>
                    <code class="card-text">&lt;img alt='text alternative goes here' src='images/example.png' /&gt;</code>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">To mark the image as <code>decorative</code> instead, add an empty <code>alt</code> attribute and <code>role: presentation<code>.</h5>
                    <code class="card-text">&lt;img alt='' role='presentation' src='images/decorative-image.png'/&gt;</code>
                </div>
            </div>
            `,
        documentation: "https://www.w3.org/WAI/tutorials/images/",
        htmlTag:"img",
        countSelector:"countImages",
        src:"srcAbsolutePath",
        hasDetails: true
    },
    {
        id:"iframe_without_alt",
        url:"iframewithoutalt",
        name:"Inline frame missing a text alternative",
        level: "A",
        description:`
            <p className="card-text">
            An iframe is an HTML element that allows you to embed a web page within another web page. It is a commonly used tool to integrate content from other pages in a specific place within a web page. It is important that iframes have descriptive text to ensure the accessibility of the web page for all people, including those with visual impairments and other disabilities who may have difficulty interacting with the content of the iframe. Descriptive text describes the content and function of the iframe, which helps users understand how it relates to the surrounding content and improves the overall user experience on the web page.

            </p>
        `,
        tips:
            `
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Add the <code>title</code> attribute in the <code>&lt;iframe&gt;</code> tag.</h5>
                    <code class="card-text">&lt;iframe&gt; src="video.html" title="Healthy Dinner Cooking Tutorial Video" &lt;/iframe&gt;</code>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Add the <code>aria-label</code> attribute in the <code>&lt;iframe&gt;</code> tag.</h5>
                    <code class="card-text">&lt;iframe&gt; src="graph.html" aria-label="This iframe displays a bar chart representing sales for the last quarter. 
                    The highest bar represents the sales in August, which was the month with the highest 
                    sales. The lowest bar represents the sales in July, which was the month with the lowest 
                    sales." &lt;/iframe&gt;</code>
                </div>
            </div>
            `,
        documentation: "https://www.w3.org/TR/WCAG20-TECHS/H64.html",
        htmlTag:"iframe",
        countSelector:"countIframe",
        src:"srcAttribute",
        hasDetails: true
    },
    {
        id:"buttons_without_alt",
        url:"buttonswithoutalt",
        name:"Button missing a text alternative",
        level: "A",
        description:`
            <p className="card-text">
            Text labels for buttons are also important for web accessibility, as users who rely on screen readers or other assistive technologies can use these labels to understand the functionality of the buttons. For this reason, it is a good practice to always include a text label on buttons, even if the text is not visible on the page.<br>
            One way to add a text label to a button without making it visible is by using HTML attributes such as <code>aria-label</code>. These attributes allow you to add additional information to page elements that is not visible in the user interface but can be read by assistive technologies. By using these attributes to add text labels to buttons, you can improve the accessibility of the page without compromising its visual appearance.
            </p>
        `,
        tips:
            `
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Adding visible text to the <code>&lt;button&gt;</code> element.</h5>
                    <code class="card-text">&lt;button&gt;Request a demo&lt;/button&gt;</code>  
                </div>
            </div>
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Using the <code>value</code> attribute on an <code>input</code> element.</h5>
                    <code class="card-text">&lt;input type="submit" value="Submit"&gt;</code>
                </div>
            </div>
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Using <code>aria-label</code> (not visible to sighted users).</h5>
                    <code class="card-text">&lt;button aria-label="Close"&gt;x&lt;/button&gt;</code>
                </div>
            </div>
            
            `,
        documentation: "https://www.w3.org/TR/WCAG20-TECHS/ARIA14.html",
        htmlTag:"button",
        countSelector:"countButton",
        src:"none",
        hasDetails: false
    },
    {
        id:"links_without_alt",
        url:"linkswithoutalt",
        name:"Link missing a text alternative",
        level: "A",
        description:`
            <p className="card-text">
            Links <code>&lt;a&gt;</code> in HTML are important for website navigation. To ensure accessibility, it is crucial to provide alternative text for links. This text describes the content or function of the link and is displayed in assistive tools and search engines. Providing meaningful alternative text improves accessibility, usability, and understanding of the content for all users.
            </p>
        `,
        tips:
            `
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Adding visible text to the <code>&lt;a&gt;</code> element.</h5>
                    <code class="card-text">&lt;a href="http://www.w3.org/WAI"&gt;Web Accessibility Initiative&lt;/a&gt;</code>  
                </div>
            </div>
            <div class="card mb-2">
                <div class="card-body">
                <h5 class="card-title">Using an <code>alt</code> attribute.</h5>
                <code class="card-text">&lt;a href="http://www.w3.org/WAI"&gt;&lt;img src="..." alt="Web Accessibility Initiative" /&gt;&lt;/a&gt;</code>
                </div>
            </div>
            <div class="card mb-2">
                <div class="card-body">
                <h5 class="card-title">Using <code>aria-label</code> (not visible to sighted users).</h5>
                <code class="card-text">&lt;a href="http://www.w3.org/WAI" aria-label="Web Accessibility Initiative"&gt;&lt;img src="..." alt="Web Accessibility Initiative" /&gt;&lt;/a&gt;</code>
                </div>
            </div>
            
            `,
        documentation: "https://www.w3.org/TR/WCAG20-TECHS/H30.html",
        htmlTag:"button",
        countSelector:"countButton",
        src:"none",
        hasDetails: false
    }
]


export const seoIssues=[
    {
        id:"pages_without_meta_descriptions_count",
        url:"missingmetadescriptions",
        name:"Missing meta descriptions",
        description:`
            <p className="card-text">The meta description is a concise summary displayed in search engine results. Creating an engaging and persuasive description is crucial for driving click-throughs to your website. Use compelling language and relevant keywords to capture users' attention and entice them to visit your page.</p>
        `,
        tips:
            `
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Add the <code>meta</code> tag in the <code>head</code> tag.</h5>
                    <code class="card-text">&lt;meta name='description' content='This is a sample meta description that will appear in search results. It must be between 110 and 160 characters long' &gt;</code>
                </div>
            </div>
            `,
        documentation: "https://developers.google.com/search/docs/appearance/snippet#:~:text=A%20meta%20description%20tag%20generally,what%20they're%20looking%20for.",
        htmlTag:"meta",
        countSelector:"count",
        src:"none",
        hasDetails: false
    },
    {
        id:"pages_multiple_h1_count",
        url:"multipleh1",
        name:"Pages with multiple H1 headers",
        description:`
            <p className="card-text">The <code>&lt;h1&gt;</code> tags play a crucial role in the structure and optimization of a web page. They represent the main heading and communicate the primary topic of the content. It is important to use only one <code>&lt;h1&gt;</code> tag per page to clearly focus the relevance and hierarchy of the content for both search engines and users. By properly utilizing <code>&lt;h1&gt;</code> tags, we enhance the readability, usability, and visibility of our page in search engine results.</p>
        `,
        tips:
            `
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Add a single <code>h1</code> tag for each page of your site</h5>
                    <code class="card-text">&lt;h1&gt; Your main heading &lt;/h1&gt;</code>
                </div>
            </div>
            `,
        documentation: "https://www.w3.org/WAI/tutorials/page-structure/headings/",
        htmlTag:"meta",
        countSelector:"count",
        src:"none",
        hasDetails: false
    },
    {
        id:"pages_without_h1_count",
        url:"missingh1",
        name:"Missing H1 tags",
        description:`
            <p className="card-text">H1 headers serve as the main heading on a page. They inform search engines about the content's topic and relevance to user search queries. Additionally, they play a vital role in aiding visitors in navigating the page, particularly those using screen readers. H1 headers provide a clear structure, enhancing content comprehension and improving user experience and search visibility.</p>
        `,
        tips:
            `
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Add a single <code>h1</code> tag for each page of your site</h5>
                    <code class="card-text">&lt;h1&gt; Your main heading &lt;/h1&gt;</code>
                </div>
            </div>
            `,
        documentation: "https://www.w3.org/WAI/tutorials/page-structure/headings/",
        htmlTag:"meta",
        countSelector:"count",
        src:"none",
        hasDetails: false
    },
    {
        id:"pages_short_title_count",
        url:"titletooshort",
        name:"Meta titles are too short",
        description:`
            <p className="card-text">The meta title, also known as the title tag, is the header line displayed in search engine result pages. Along with the meta description, it is potentially one of the only pieces of content a user will see of your site, making it critical to determining the number of click-throughs your site will receive. If the meta title is too short, it may lack sufficient information to persuade a user to click on your link. It is essential to create compelling, descriptive, and relevant meta titles that capture users' attention and motivate them to visit your website over other search results. By investing time and effort into optimizing your meta titles, you can increase the chances of generating more traffic and click-throughs to your site.</p>
        `,
        tips:
            `
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Add a <code>title</code> tag for each page of your site in your <code>head</code> tag</h5>
                    <code class="card-text">&lt;title&gt; Your title page here. Must be 40 to 75 characters long &lt;/title&gt;</code>
                </div>
            </div>
            `,
        documentation: "https://www.w3.org/WAI/tutorials/page-structure/headings/",
        htmlTag:"meta",
        countSelector:"count",
        src:"none",
        hasDetails: false
    },
    {
        id:"short_meta_descriptions_count",
        url:"descriptionstooshort",
        name:"Meta descriptions are too short",
        description:`
            <p className="card-text">The meta title, also known as the title tag, is the header line displayed in search engine result pages. Along with the meta description, it is potentially one of the only pieces of content a user will see of your site, making it critical to determining the number of click-throughs your site will receive. If the meta title is too short, it may lack sufficient information to persuade a user to click on your link. It is essential to create compelling, descriptive, and relevant meta titles that capture users' attention and motivate them to visit your website over other search results. By investing time and effort into optimizing your meta titles, you can increase the chances of generating more traffic and click-throughs to your site.</p>
        `,
        tips:
            `
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Add the <code>meta</code> tag in the <code>head</code> tag.</h5>
                    <code class="card-text">&lt;meta name='description' content='This is a sample meta description that will appear in search results. It must be between 110 and 160 characters long' &gt;</code>
                </div>
            </div>
            `,
        documentation: "https://www.w3.org/WAI/tutorials/page-structure/headings/",
        htmlTag:"meta",
        countSelector:"count",
        src:"none",
        hasDetails: false
    },
    {
        id:"pages_empty_meta_descriptions_count",
        url:"emptymetadescriptions",
        name:"Empty meta descriptions",
        description:`
            <p className="card-text">A meta description appears as a concise summary of a page's content in search engine results. Along with the meta title, it is potentially one of the only pieces of content a user will see of your site, making it crucial for determining the number of click-throughs your site will receive. If the meta description is too short, it may lack sufficient information to persuade a user to click on your link. It is essential to create compelling, descriptive, and relevant meta descriptions that capture users' attention and motivate them to visit your website over other search results. By investing time and effort into optimizing your meta descriptions, you can increase the chances of generating more traffic and click-throughs to your site.</p>
        `,
        tips:
            `
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Add the <code>meta</code> tag in the <code>head</code> tag.</h5>
                    <code class="card-text">&lt;meta name='description' content='This is a sample meta description that will appear in search results. It must be between 110 and 160 characters long' &gt;</code>
                </div>
            </div>
            `,
        documentation: "https://www.w3.org/WAI/tutorials/page-structure/headings/",
        htmlTag:"meta",
        countSelector:"count",
        src:"none",
        hasDetails: false
    },
    {
        id:"pages_long_title_count",
        url:"titletoolong",
        name:"Meta titles are too long",
        description:`
            <p className="card-text">The meta title, also known as the title tag, is the header line displayed in search engine result pages. Along with the meta description, it is potentially one of the only pieces of content a user will see of your site, making it critical to determining the number of click-throughs your site will receive. If the meta title is too short, it may lack sufficient information to persuade a user to click on your link. It is essential to create compelling, descriptive, and relevant meta titles that capture users' attention and motivate them to visit your website over other search results. By investing time and effort into optimizing your meta titles, you can increase the chances of generating more traffic and click-throughs to your site.</p>
        `,
        tips:
            `
            <div class="card mb-2">
                <div class="card-body">
                    <h5 class="card-title">Add a <code>title</code> tag for each page of your site in your <code>head</code> tag</h5>
                    <code class="card-text">&lt;title&gt; Your title page here. Must be 40 to 75 characters long &lt;/title&gt;</code>
                </div>
            </div>
            `,
        documentation: "https://www.w3.org/WAI/tutorials/page-structure/headings/",
        htmlTag:"meta",
        countSelector:"count",
        src:"none",
        hasDetails: false
    },
    
]


export const countAllIssues=(completeItem, arr)=>{
    let issues = [];
    arr.map(item=>{
        if(item.id in completeItem){
            issues.push(item.id)
        }
    })
    return issues
}

export const searchAttributeIssue=(attr,issueId, arr)=>{
    let value = ""
    arr.map(item=>{
        if(item.id == issueId){
            value = item[attr]
        }
    })
    return value
}

export const searchNameIssue=(issueId, arr)=>{
    let nameIssue=""
    arr.map(item=>{
        if(item.id == issueId){
            nameIssue=item.name
        }
    })
    return nameIssue
}

export const searchUrlIssue=(issueId)=>{
    let urlIssue=""
    accesibilityIssues.map(item=>{
        if(item.id == issueId){
            urlIssue=item.url
        }
    })
    return urlIssue
}

export const searchLevelIssue=(issueId)=>{
    let levelIssue=""
    accesibilityIssues.map(item=>{
        if(item.id == issueId){
            levelIssue=item.level
        }
    })
    return levelIssue
}

export const createVisualTag=(tag,src)=>{
    return `<${tag} src="${src}"></${tag}>`
}

export const searchTechnicalDebt=(data, issue)=>{
    console.log(data, issue)
    if(data == null){
        return 0
    }
    let technicalDebt = data.filter(c=>c.category==issue)[0]
    console.log(technicalDebt)
    let time = technicalDebt?.debt_technical_value
    if(time){
        return time
    }else{
        return 0
    } 
}

export const calculateTotalTechnicalDebt=(website, values)=>{
    let total = 0;
    values.map(item=>{
        let itemUpdate = item.category
        console.log(item?.debt_technical_value , website[item.category], item.category)
        if(item.category == "broken_link"){
            
            itemUpdate = "broken_links"
        }
        total += (item?.debt_technical_value * website[itemUpdate]) 
    })
    console.log(total)
    return total
}

export const getDomain=(path)=>{
    const url = new URL(path);
    return url.origin;
}

export const createAbsolutePath=(domain,path)=>{
    if(path.startsWith('/')){
        const absolutePath = new URL(path, domain)
        return absolutePath.toString();
    }else{
        return path;
    }
}