import React, { useEffect, useState } from "react"
import { Cart, Gear, Reception1, Reception4 } from "react-bootstrap-icons"
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import { axiosUserLog } from "../helpers/axiosInstance";
import { customBasicMessageModal, customBasicMessageToast } from "../helpers/messages";
import { PayPalButton } from 'react-paypal-button-v2';
import { Link } from "react-router-dom";

const PlanUpdateForm=({id,hideModal, setSavePlan,savePlan, defaultValues})=>{
    const [totalPages, setTotalPages] = useState(defaultValues.totalPages);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axiosUserLog().patch(`plans/${id}/`, {
                max_pages: parseInt(totalPages),
            });
        setSavePlan(!savePlan)
        customBasicMessageToast(1500,"success","Plan successfully updated")
        } catch (err) {
            customBasicMessageModal("error","Error updating the plan","Try again")
        }
        hideModal();
    };
    return(
        <form className="row" onSubmit={handleSubmit}>
            <div className="col-12 form-group">
                <button type="button" className="close" aria-label="Close" onClick={hideModal}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <h3 className="text-center text-secondary" >Customize your plan</h3>
            </div>
            <div className="col-12 form-group pb-3" >
                <label >Total pages:</label>
                <input type="number" defaultValue={defaultValues.totalPages} className="form-control" id="inputTotalPagesWebsite" placeholder="Enter the total number of pages per website to be analyzed " name="inputTotalPagesWebsite" min="0" required onChange={(e)=>setTotalPages(e.target.value)}/>            
            </div>
            <div className="col-6 small">
                <button type="button" className="btn btn-secondary col-12 " onClick={hideModal}>Close</button>
            </div>
            <div className="col-6 small">
                <button type="submit" className="btn btn-success col-12 ">Save</button>
            </div>
        </form>
    )
}

const CardPlan=({id,state,title,priceMonth,totalPages,priceYear, savePlan, setSavePlan})=>{
    const defaultValues = {totalPages}
    const [showModal, hideModal] = useModal(() => {
        return(
            <ReactModal isOpen className="p-3 mx-auto bg-white border col-10 col-md-5 mt-lg-5" ariaHideApp={false}>
                <PlanUpdateForm id={id} hideModal={hideModal} setSavePlan={setSavePlan} savePlan={savePlan} defaultValues={defaultValues}/>
            </ReactModal>
        )
        }
    ,[defaultValues, savePlan]);
    return(
        <div className=" card  mb-4 box-shadow col-11 col-sm-3 mt-5 border m-3 pb-4 h-50">
            <div className="card-header bg-white text-center ">
                <Reception4 className="mr-2 my-3" color="gray" size={100} />
                <h2 className="my-0 font-weight-normal text-muted">{title}</h2>
                <h1 className="card-title pricing-card-title text-success">${parseFloat(priceMonth).toFixed(2)} <small
                    className="text-muted">/ mo</small></h1>
                <h5 className="card-title pricing-card-title">1$ per page per year</h5>
            </div>
            <div className="card-body">
                <ul className="list-unstyled mt-3 mb-4 text-muted">
                    <li><h4 className="card-title pricing-card-title text-success"><small
                        className="text-muted">Total pages: </small>{totalPages}</h4></li>
                    <li><h4 className="card-title pricing-card-title text-success"><small
                        className="text-muted">Cost per year: </small><br></br>${priceYear} USD</h4></li>
                </ul>
                {
                    !state
                    ?
                    <>
                        <button type="button"
                            className="btn btn-lg btn-block btn-outline-info" onClick={showModal}>
                            <Gear className="mr-1 my-1 " size={30} /> Customize plan
                        </button>
                        <Link to={`/main/summary-purchase/${id}`} type="button"
                            className="btn btn-lg btn-block btn-outline-success">
                            <Cart className="mr-1 my-1 " size={30} /> Buy
                        </Link>
                    </>
                    :
                    <>
                        <button type="button" disabled
                            className="btn btn-lg btn-block btn-outline-info">
                            <Cart className="mr-1 my-1 " size={30} /> Your current plan
                        </button>
                    </>
                }
            </div>
        </div>
        )
}

export default CardPlan
