import React, { useEffect } from 'react';
import { Link, useParams, useHistory  } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { KeyFill } from 'react-bootstrap-icons';
import Icon from '../img/icon.png';
import axios from 'axios';
import { customBasicMessageModal } from '../helpers/messages';
import axiosInstance from '../helpers/axiosInstance';

const Reset = () => {
    const { register, errors, handleSubmit } = useForm();
    const data = useParams();
    let history = useHistory(); 
    useEffect(() => {
        reset()
    }, [])

    const reset = () => {
        const url = `password-token-check/${data.user}/${data.token}`;
        axiosInstance(url).then(
            response => {   
                customBasicMessageModal("success","Email verification successful","")
            },
            err => {
                customBasicMessageModal("error","Error verifying email!","")
                history.push("/forgot");
            })
    }


    const onSubmit =async () => {
        
        var password = document.getElementById('PasswordInput');
        var password_repeat = document.getElementById("RepeatPasswordInput");

        const url = `password-recovery-complete/`;

        try {
            await axiosInstance.patch(url, {
                "password": password.value,
                "uidb64": data.user,
                "token": data.token,
                "confirm_password": password_repeat.value,
            })
            customBasicMessageModal("success","Password changed successfully","")
            history.push("/login");

        } catch (error) {
            customBasicMessageModal("success","An error occurred during the update","Verify that your password meets the minimum requirements and try again.")
        }
    }
    return (
        <div className="container pt-md-5">
            <div className="row justify-content-center pt-md-5">
                <div className="col col-md-8 pt-md-5">
                    <div className="card  my-5 shadow-sm shadow-hover">
                        <div className="col-12 user-img my-4">
                            <img src={Icon} alt="Logo bye bye 404" className="d-block mx-auto "></img>
                        </div>
                        <div className="card-body p-0 ">
                            <div className="row">
                                <div className="col">
                                    <div className="px-5 pb-5">
                                        <div className="text-center">
                                            <h4 className="text-dark mb-2">Change Password</h4>
                                            <p className="mb-4">Enter a new password and enter the new password again and click save.</p>
                                        </div>
                                        <form className="reset text-center" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="form-group row  pl-md-3 pl-xl-5">
                                                <label htmlFor="PasswordInpu" className="col-12 text-left">New password:</label>
                                                <KeyFill className="mx-3" size={35} />
                                                <input className="form-control form-control-user  col-9 "
                                                    type="password"
                                                    id="PasswordInput"
                                                    placeholder="New password"
                                                    name="password"
                                                    ref={register({
                                                        required: {
                                                            value: true,
                                                            message: 'Password required'
                                                        },
                                                        maxLength: {
                                                            value: 15,
                                                            message: 'No more than 15 characters!'
                                                        },
                                                        minLength: {
                                                            value: 8,
                                                            message: 'Minimum 8 characters'
                                                        },

                                                    })}
                                                    required />
                                                <span className="text-danger text-small d-block mb-2 ml-5 pl-lg-3">
                                                    {errors?.password?.message}
                                                </span>
                                            </div>
                                            <div className="form-group row  pl-md-3 pl-xl-5">
                                                <label htmlFor="RepeatPasswordInput" className="col-12 text-left">Confirm your new password:</label>
                                                <KeyFill className="mx-3" size={35} />
                                                <input className="form-control form-control-user   col-9 "
                                                    type="password"
                                                    id="RepeatPasswordInput"
                                                    placeholder="Confirm your new password"
                                                    name="password_repeat"
                                                    ref={register({
                                                        required: {
                                                            value: true,
                                                            message: 'Repeat Password required'
                                                        },
                                                        maxLength: {
                                                            value: 15,
                                                            message: 'No more than 15 characters!'
                                                        },
                                                        minLength: {
                                                            value: 8,
                                                            message: 'Minimum 8 characters'
                                                        }
                                                    })}
                                                    required />
                                                <span className="text-danger text-small d-block mb-2 ml-5 pl-lg-3">
                                                    {errors?.password_repeat?.message}
                                                </span>
                                            </div>
                                            <button className="btn btn-success col-11 text-white btn-user"
                                                type="submit">Save
                                        </button>
                                        </form>
                                        <div className="text-center mt-3 ">
                                            <Link className=" text-primary" to="/login">Go to login!</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reset;