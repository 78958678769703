import { useState } from "react";
import "./Details.css";
import React from "react";
import DetailsCard from "./DetailsCard";
const Details = () => {
  const [isFlipCard1, setIsFlipCard1] = useState(false);
  const [isFlipCard2, setIsFlipCard2] = useState(false);
  const [isFlipCard3, setIsFlipCard3] = useState(false);
  const [isFlipCard4, setIsFlipCard4] = useState(false);
  const [isFlipCard5, setIsFlipCard5] = useState(false);
  const [isFlipCard6, setIsFlipCard6] = useState(false);
  const [isFlipCard7, setIsFlipCard7] = useState(false);
  const [isFlipCard8, setIsFlipCard8] = useState(false);
  return (
    <>
      <div className="details-container landing-container main-section" id="details">
        <h2 className="section-title">Details</h2>
        <div className="details-main-container">
          <div className="accessibility-features-container">
            <p className="section-subtitle">Accessibility</p>
            <DetailsCard
              handleFlip={setIsFlipCard1}
              isFlip={isFlipCard1}
              imgCard="/assets/image_upload.png"
              altImg="Images without alt text"
              titleCard="Images without Alternative Text"
              textCard="Images without alt text can hinder website accessibility and
                  SEO. Visually impaired users are unable to comprehend image
                  content, resulting in a limited user experience. Moreover,
                  search engines struggle to index them properly, impacting
                  website visibility.
                  With Bye Bye 404, quickly identify and address images lacking
                  alt text. Benefit from tailored suggestions to enhance user
                  experience and boost search engine visibility."
            />
            <DetailsCard
              handleFlip={setIsFlipCard2}
              isFlip={isFlipCard2}
              imgCard="/assets/media_player.png"
              altImg="Iframes without alt text"
              titleCard="Iframes without Alternative Text"
              textCard="Enhance accessibility and SEO of your website by identifying
              iframes without alternative text. Iframes are used to display
              content from other websites and require appropriate
              descriptions. Bye Bye 404 detects and highlights iframes
              without alternative text, empowering you to make necessary
              corrections for an improved user experience."
            />
            <DetailsCard
              handleFlip={setIsFlipCard3}
              isFlip={isFlipCard3}
              imgCard="/assets/link_shortener.png"
              altImg="Links without alt text"
              titleCard="Links without Alternative Text"
              textCard="Don't let links without alternative text hinder the
              accessibility and SEO of your website. Links without proper
              descriptions make navigation difficult for visually impaired
              individuals and impact search engine indexing. With Bye Bye
              404, quickly identify links that lack alternative text and
              take steps to enhance the user experience and visibility of
              your site."
            />
            <DetailsCard
              handleFlip={setIsFlipCard4}
              isFlip={isFlipCard4}
              imgCard="/assets/usability_testing.png"
              altImg="Buttons without alt text"
              titleCard="Buttons without Alternative Text"
              textCard="Don't let your buttons go unnoticed. Buttons without an
              accessible description make interaction difficult for visually
              impaired users and impact search engine optimization. With Bye
              Bye 404, quickly identify buttons that lack proper
              descriptions and take action to improve the user experience
              and increase the visibility of your site."
            />
          </div>

          <div className="accessibility-features-container">
            <p className="section-subtitle">SEO</p>
            <DetailsCard
              handleFlip={setIsFlipCard5}
              isFlip={isFlipCard5}
              imgCard="/assets/broken_links.png"
              altImg="Broken links"
              titleCard="Broken Links and Risky Domains"
              textCard="Enhance user experience and safeguard your website by
              addressing broken links and risky domains. Broken links can
              negatively impact SEO and credibility, while risky domains
              compromise security. With Bye Bye 404, quickly detect broken
              links and mitigate risks associated with suspicious domains.
              Take proactive measures to ensure user satisfaction and site
              security."
            />
            <DetailsCard
              handleFlip={setIsFlipCard6}
              isFlip={isFlipCard6}
              imgCard="/assets/meta_descriptions.png"
              altImg="Missing meta descriptions"
              titleCard="Missing meta descriptions"
              textCard="The meta description is a concise summary displayed in search engine results. Creating an engaging and persuasive description is crucial for driving click-throughs to your website. Use compelling language and relevant keywords to capture users' attention and entice them to visit your page."
            />
            <DetailsCard
              handleFlip={setIsFlipCard7}
              isFlip={isFlipCard7}
              imgCard="/assets/missing_h1.png"
              altImg="Missing H1 tags"
              titleCard="Missing H1 tags"
              textCard="H1 headers serve as the main heading on a page. They inform search engines about the content's topic and relevance to user search queries. Additionally, they play a vital role in aiding visitors in navigating the page, particularly those using screen readers. H1 headers provide a clear structure, enhancing content comprehension and improving user experience and search visibility."
            />
            <DetailsCard
              handleFlip={setIsFlipCard8}
              isFlip={isFlipCard8}
              imgCard="/assets/meta_titles_too_short.png"
              altImg="Meta titles are too short"
              titleCard="Meta titles are too short"
              textCard="The meta title, also known as the title tag, is the header line displayed in search engine result pages. Along with the meta description, it is potentially one of the only pieces of content a user will see of your site, making it critical to determining the number of click-throughs your site will receive. If the meta title is too short, it may lack sufficient information to persuade a user to click on your link. It is essential to create compelling, descriptive, and relevant meta titles that capture users' attention and motivate them to visit your website over other search results."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
