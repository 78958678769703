import React from "react";
const FilterComponent = ({ filterText, onFilter, onClear, placeholder }) => (
    <div className="">
        <div className="input-group col ">
            <input id="search" type="text" className="form-control ml-2" placeholder={placeholder} value={filterText} onChange={onFilter} />
            <div className="input-group-append">
                <button className="btn btn-secondary small" type="button" onClick={onClear}>X</button>
            </div>
        </div>
    </div>
);

export default FilterComponent