import React from 'react';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Register from './pages/Register'
import PageNotFound from './components/PageNotFound';
import ResetPassword from './pages/ResetPassword';
import ActivateAccount from './pages/ActivateAccount';


import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import decode from 'jwt-decode';

import HomePage from './pages/landing_pages/HomePage';
import PrivacyPolicy from './pages/landing_pages/PrivacyPolicy';
import TermsAndConditions from './pages/landing_pages/TermsAndConditions';

const isAuthenticated = () => {
	const token = localStorage.getItem('token')
	let isValid = true
	try {
		isValid = decode(token);
	} catch (e) {
		return false;
	}
	return isValid;
};

const MyRoute = (props) => (
	isAuthenticated()
		? <Route {...props} />
		: <Redirect to="/login" />
)

const Logout = () => {
	localStorage.clear();
	return <Redirect to="/login" />
}

const App = () => {
		return (
			<BrowserRouter>
				<Switch>
					<Route path='/login' component={Login} />
					<MyRoute path='/main' component={Dashboard} />
					<Route path='/password-recovery' component={ForgotPassword} />
					<Route path='/password-change/:user/:token' component={ResetPassword} />
					<Route path='/activate/:token' component={ActivateAccount} />
					<Route path='/signup' component={Register} />				
					<Route path='/logout' exact component={Logout} />
					<Route path='/terms-conditions' exact component={TermsAndConditions} />
					<Route path='/privacy-policy' exact component={PrivacyPolicy} />
					<Route path='/' exact component={HomePage} />
					<Route component={PageNotFound} />
				</Switch>
			</BrowserRouter>
		);
}

export default App;
