import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <>
      <div className="main-container main-section landing-container" id="home">
        <div className="main-container-image">
          <img src="/assets/web_search.png" alt="Contro panel" />
        </div>
        <div className="main-container-slogan">
          <p>
            Optimize your website to the fullest with{" "}
            <strong>Bye Bye 404</strong>: Your ally in accessibility and SEO.
          </p>
          <Link 
            to="/signup"
            className="primary-button"
          >
            Try now
          </Link>
        </div>
      </div>
    </>
  );
};

export default Home;
